import { NavbarGroup, NavbarOption } from '@app/interfaces/navbar';
import {
  // please keep this list sorted
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faBadgeDollar,
  faCalendar,
  faChartColumn,
  faCircleUser,
  faCommentsDollar,
  faCreditCard,
  faCreditCardBlank,
  faFileInvoiceDollar,
  faFileLines,
  faGear,
  faHouse,
  faLinkSimple,
  faMap,
  faMobile,
  faMoneyCheckDollar,
  faMoneyCheckPen,
  faOctagonExclamation,
  faShieldCheck,
  faTag,
  faTruck,
  faTruckFront,
  faWallet,
} from '@fortawesome/pro-regular-svg-icons';

import type { CustomerProduct } from '@app/constants/customerProducts';

const FEUL_DISCOUNT_OPTION: NavbarOption = {
  key: 'fuel_discounts',
  link: '/discounts',
  icon: faBadgeDollar,
  message: 'Fuel Discounts',
  requires: ['upside'],
};

const INDEX_GROUP_OPTIONS: NavbarOption[] = [
  {
    key: 'index-transactions',
    link: '/transactions',
    showActiveOn: ['/transaction_details'],
    icon: faArrowRightArrowLeft,
    message: 'Transactions',
    requires: ['cards'],
  },
  {
    key: 'suspicious_activities',
    link: '/telematics/suspicious-activities',
    icon: faOctagonExclamation,
    message: 'Suspicious Activities',
    requires: ['suspicious_activities'],
  },
  {
    key: 'wallet_overview',
    link: '/wallet/overview',
    icon: faWallet,
    message: 'Wallet',
    requires: ['treasury'],
  },
  {
    key: 'wallet_funding',
    link: '/wallet/funding',
    icon: faArrowRight,
    message: 'Funding Requests',
    requires: ['funding_requests'],
  },
  {
    key: 'fuel-map',
    link: '/fuel-map',
    icon: faMap,
    message: 'Fuel Map',
    requires: ['fuel_finder'],
    event: 'nav_fuel_map_new',
  },
  {
    key: 'index-discount-catalog',
    link: '/discounts-catalog',
    icon: faTag,
    message: 'Discounts Catalog',
    requires: ['perks'],
  },
  {
    key: 'reporting',
    link: '/reporting',
    icon: faChartColumn,
    message: 'Reporting',
    requires: ['reporting'],
  },
];

const INDEX_NAVBAR_GROUP: NavbarGroup = {
  key: 'index',
  options: [
    {
      key: 'index-dashboard',
      link: '/',
      icon: faHouse,
      message: 'Home',
      requires: ['dashboard'],
    },
    ...INDEX_GROUP_OPTIONS,
    {
      key: 'payroll_overview',
      link: '/payroll/overview',
      showActiveOn: ['/payroll/history'],
      icon: faMoneyCheckDollar,
      message: 'Driver Pay',
      requires: ['payroll_overview'],
    },
    FEUL_DISCOUNT_OPTION,
  ],
};

const PAYROLL_ONLY_NAVBAR_GROUP: NavbarGroup = {
  key: 'index',
  options: [
    {
      key: 'payroll_overview',
      link: '/payroll/overview',
      showActiveOn: ['/payroll/history', '/'],
      icon: faMoneyCheckDollar,
      message: 'Driver Pay',
      requires: ['payroll_overview'],
    },
    ...INDEX_GROUP_OPTIONS,
    FEUL_DISCOUNT_OPTION,
  ],
};

const COMPANY_NAVBAR_GROUP: NavbarGroup = {
  title: 'Company',
  key: 'company',
  options: [
    {
      key: 'index-cards',
      link: '/cards',
      icon: faCreditCardBlank,
      message: 'Cards',
      requires: ['cards'],
    },
    {
      key: 'index-drivers',
      link: '/drivers',
      icon: faCircleUser,
      message: 'Drivers',
      requires: ['drivers'],
    },
    {
      key: 'index-vehicles',
      link: '/vehicles',
      icon: faTruckFront,
      message: 'Vehicles',
      requires: ['vehicles'],
    },
    {
      key: 'index-devices',
      link: '/devices',
      icon: faMobile,
      message: 'Devices',
      requires: ['devices'],
    },
    {
      key: 'index-tags',
      link: '/tags',
      icon: faShieldCheck,
      message: 'Tags & Policies',
      requires: ['resource_tags'],
    },
  ],
};

const BILLING_NAVBAR_GROUP: NavbarGroup = {
  title: 'Billing',
  key: 'billing',
  options: [
    {
      key: 'statements',
      link: '/billing/statements',
      icon: faFileLines,
      message: 'Statements',
      requires: ['billing'],
    },
    {
      key: 'payment_history',
      link: '/billing/payment-history',
      icon: faCalendar,
      message: 'Payment History',
      requires: ['billing'],
    },
    {
      key: 'payment_methods',
      link: '/billing/payment-methods',
      icon: faMoneyCheckPen,
      message: 'Payment Methods',
      requires: ['payment_methods'],
    },
    {
      key: 'quickbooks',
      link: '/accounting/quickbooks',
      icon: faFileInvoiceDollar,
      message: 'Quickbooks',
      requires: ['quickbooks'],
    },
    {
      key: 'accounting',
      link: '/accounting',
      icon: faFileInvoiceDollar, // Same as QB for now, eventually merging the two tabs
      message: 'Accounting',
      requires: ['accounting'],
    },
  ],
};

const ACCOUNT_NAVBAR_GROUP: NavbarGroup = {
  title: 'account',
  key: 'account',
  options: [
    {
      key: 'refer_earn',
      link: '/referrals',
      icon: faCommentsDollar,
      message: 'Refer & Earn',
      requires: ['refer_and_earn'],
    },
    {
      key: 'integrations',
      link: '/integrations',
      icon: faLinkSimple,
      message: 'Integrations',
      requires: ['integrations'],
    },
    {
      key: 'settings',
      link: '/settings',
      icon: faGear,
      message: 'Settings',
      requires: [],
    },
    {
      key: 'users',
      link: '/users',
      icon: faCircleUser,
      message: 'Users',
      requires: ['workspace'],
    },
    {
      key: 'logout',
      link: '/logout',
      icon: faArrowRightFromBracket,
      message: 'Log out',
      requires: ['logout'],
    },
  ],
};

export const EMBEDDED_NAV_OPTIONS: NavbarOption[] = [
  {
    key: 'index-cards',
    link: '/cards',
    icon: faCreditCard,
    message: 'Manage fuel cards',
    requires: ['cards'],
  },
  {
    key: 'index-drivers',
    link: '/fleet',
    icon: faTruck,
    message: 'Manage fleet',
    requires: ['drivers', 'vehicles'],
  },
  {
    key: 'statements',
    link: '/billing/statements',
    icon: faFileLines,
    message: 'Billing details',
    requires: ['billing'],
  },
];

export const getNavBarItems = (
  products: CustomerProduct[],
  isStreamlinedDriverAppOnboarding: boolean,
): NavbarGroup[] => {
  const payrollOnly = !products.includes('fuel_card') && products.includes('payroll');
  const hasSettingsEnabled = products.some((p) => p.includes('notification'));

  const indexGroupTemp = payrollOnly ? PAYROLL_ONLY_NAVBAR_GROUP : INDEX_NAVBAR_GROUP;

  const indexGroup = {
    ...indexGroupTemp,
    options: indexGroupTemp.options.filter(
      (i) => i.key !== 'fuel_discounts' || !isStreamlinedDriverAppOnboarding,
    ),
  };

  return [
    indexGroup,
    COMPANY_NAVBAR_GROUP,
    BILLING_NAVBAR_GROUP,
    hasSettingsEnabled
      ? ACCOUNT_NAVBAR_GROUP
      : {
          ...ACCOUNT_NAVBAR_GROUP,
          options: ACCOUNT_NAVBAR_GROUP.options.filter((o) => o.key !== 'settings'),
        },
  ];
};

export const computeEnabledGroups = (
  products: CustomerProduct[],
  groups: NavbarGroup[],
): NavbarGroup[] => {
  const requirementsSatisfied = (option: NavbarOption): boolean => {
    return option.requires.every((requirement) => products.includes(requirement));
  };

  return groups
    .map((group) => {
      group.options = group.options.filter((option) => {
        return requirementsSatisfied(option);
      });

      return group;
    })
    .filter((group) => group.options.length > 0);
};
