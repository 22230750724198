import { useAstraOnboardingStatusQuery } from '@app/hooks/query/useAstraOnboardingStatusQuery';
import useCustomer from '@app/hooks/useCustomer';
import { getEnvironment } from '@app/utils/environment';
import { guardAxiosError } from '@app/utils/error/guards';
import { isOTPError } from '@app/utils/error/isRetryableError';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  AmountInput,
  DescriptionInput,
  DestinationSelector,
  PaymentMethodSelector,
} from './Inputs';
import { ConfirmationModal, SuccessModal } from './Modals';
import {
  FeeConfiguration,
  filterDestinationsBySelectedMethod,
  NormalizedDestination,
  PaymentMethodType,
  WithdrawalState,
  withdrawFunds,
} from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Astra: any;

export const TransferPanel = ({
  loadingMethods,
  loadingDestinations,
  availableMethods,
  availableDestinations,
  withdrawalState,
  setWithdrawalState,
  reset,
  onCompletedTansfer,
  walletBalance,
}: {
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  loadingMethods: boolean;
  loadingDestinations: boolean;
  withdrawalState: WithdrawalState;
  setWithdrawalState: (value: WithdrawalState) => void;
  reset: () => void;
  onCompletedTansfer: () => void;
  walletBalance: string | null;
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<NormalizedDestination | null>(
    null,
  );
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const { data: astraStatus } = useAstraOnboardingStatusQuery();
  const eligibleForAstra = !(astraStatus?.onboarding_status == 'not_eligible');
  const { VITE_ASTRA_CLIENT_ID } = getEnvironment();
  const customer = useCustomer();
  const { addToast } = useToasts();
  const filteredDestinations = useMemo(() => {
    return filterDestinationsBySelectedMethod(availableDestinations, selectedMethod);
  }, [availableDestinations, selectedMethod]);
  const authorizeAstra = async (code: string) => {
    // eslint-disable-next-line no-console
    console.log(`onAuth: code=${code}`);
    try {
      await axios.post('/treasury/astra/authorization', {
        authorization_code: code,
      });

      setWithdrawalState('confirmation');
    } catch (error: unknown) {
      if (error instanceof Error) {
        addToast(error.message, { appearance: 'error' });
      } else {
        // Handle the case where the error is not an instance of Error
        addToast('Something went wrong. Please try again later.', { appearance: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const collectAstraAuthorization = async (businessProfileId: string) => {
    try {
      const handler = Astra.create({
        actionType: 'COLLECT_AUTHORIZATION',
        business: true,
        businessProfileId: businessProfileId,
        bypassConnect: true,
        clientId: VITE_ASTRA_CLIENT_ID,
        phone: customer.owner_phone,
        phoneReadOnly: true,
        redirectUri: 'https://app.atob.com',
        onAuth: async (code: string) => {
          authorizeAstra(code);
        },
        onClose: () => {
          setWithdrawalState('initial');
        },
        onError: () => {
          setLoading(false);
          setWithdrawalState('initial');
          addToast('Something went wrong. Please try again later.', { appearance: 'error' });
        },
      });
      handler.open();
    } catch (e: unknown) {
      setLoading(false);
      setWithdrawalState('initial');
      addToast('Something went wrong. Please try again later.', { appearance: 'error' });
    }
  };

  const onboardAstra = async () => {
    try {
      setLoading(true);
      const result = await axios.post('/treasury/astra/onboarding');
      const { business_profile_id, onboarding_status } = result.data;

      switch (onboarding_status) {
        case 'not_eligible':
          break;
        case 'needs_authorization':
          collectAstraAuthorization(business_profile_id);
          break;
        case 'active':
          setLoading(false);
          setWithdrawalState('confirmation');
          break;
      }
    } catch (error: unknown) {
      setLoading(false);

      if (error instanceof Error) {
        addToast(error.message, { appearance: 'error' });
      } else {
        // Handle the case where the error is not an instance of Error
        addToast('Something went wrong. Please try again later.', { appearance: 'error' });
      }
    }
  };

  const submitFunds = async ({
    destination,
    selectedTransferMethod,
  }: {
    destination: NormalizedDestination;
    selectedTransferMethod: FeeConfiguration;
  }) => {
    if (withdrawalState === 'initial') {
      if (eligibleForAstra) {
        onboardAstra();
      } else {
        setWithdrawalState('confirmation');
      }
      return;
    }

    setLoading(true);

    try {
      await withdrawFunds(
        amount,
        description,
        uuid(),
        selectedTransferMethod,
        destination.id,
        destination.recipientType === 'own' ? 'own_transfer' : 'external_transfer',
      );
      onCompletedTansfer();
      setWithdrawalState('success');
    } catch (e: unknown) {
      if (isOTPError(e)) {
        // Skip showing this error, because it will be intercepted by the OTP handler
        return;
      }

      const defaultError = 'Something went wrong. Please try again later.';
      if (guardAxiosError(e)) {
        const message = `There was an error: ${e?.response?.data?.errors?.[0] || defaultError}`;
        addToast(message, { appearance: 'error' });
      } else {
        addToast(defaultError, { appearance: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const selectedMethodConfiguration = availableMethods.find(
    (method) => method.type === selectedMethod,
  );

  return (
    <div className="flex flex-col gap-6">
      <PaymentMethodSelector
        availableMethods={availableMethods}
        availableDestinations={availableDestinations}
        selectedDestination={selectedDestination}
        selectedMethod={selectedMethod}
        setSelectedDestination={setSelectedDestination}
        setSelectedMethod={setSelectedMethod}
        loadingMethods={loadingMethods}
      />
      <DestinationSelector
        availableDestinations={filteredDestinations}
        selectedDestination={selectedDestination}
        selectedMethod={selectedMethod}
        setSelectedDestination={setSelectedDestination}
        loadingDestinations={loadingDestinations}
      />
      <AmountInput amount={amount} setAmount={setAmount} walletBalance={walletBalance} />
      <DescriptionInput description={description} setDescription={setDescription} />
      <LoadingButton
        color="primary"
        fullWidth
        onClick={() =>
          submitFunds({
            destination: selectedDestination!,
            selectedTransferMethod: selectedMethodConfiguration!,
          })
        }
        disabled={!selectedMethod || !selectedDestination || !amount}
        loading={loading}
        endIcon={<FontAwesomeIcon icon={faArrowUp} />}
      >
        <span>Transfer funds</span>
      </LoadingButton>
      {withdrawalState === 'confirmation' && (
        <ConfirmationModal
          open
          onClose={() => setWithdrawalState('initial')}
          destination={selectedDestination!}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
          onConfirm={() =>
            submitFunds({
              destination: selectedDestination!,
              selectedTransferMethod: selectedMethodConfiguration!,
            })
          }
          loading={loading}
        />
      )}
      {withdrawalState === 'success' && (
        <SuccessModal
          open
          onClose={reset}
          destination={selectedDestination!}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
        />
      )}
    </div>
  );
};
