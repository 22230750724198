import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

export function ModalHeader({
  title,
  onClose,
  className,
  headerImage,
  children,
  showHeaderImageWithoutPadding = false,
}: {
  title: string;
  onClose?: () => void;
  className?: string;
  headerImage?: ReactElement;
  children?: React.ReactNode;
  showHeaderImageWithoutPadding?: boolean;
}): ReactElement {
  return (
    <DialogTitle className={headerImage && showHeaderImageWithoutPadding ? 'p-0' : ''}>
      {headerImage}
      <div
        className={classNames(
          'flex items-center gap-4',
          headerImage ? (showHeaderImageWithoutPadding ? 'px-8 pt-6' : 'pt-6') : '',
          className,
        )}
      >
        <div className="min-h-8 text-2xl font-semibold tracking-tight">{title}</div>
        {onClose && <ModalCloseButton onClick={onClose} />}
      </div>
      {children}
    </DialogTitle>
  );
}

export function ModalCloseButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton className="absolute right-4 top-4 " onClick={onClick}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );
}

export const ModalBodyContent = ({
  children,
  overflowVisible = false,
  fullScreen = false,
}: {
  children: React.ReactNode;
  overflowVisible?: boolean;
  fullScreen?: boolean;
}): ReactElement => {
  return (
    <DialogContent
      classes={{
        root: classNames({
          'overflow-y-visible': overflowVisible,
          'overflow-y-scroll': !overflowVisible,
          'sm:h-auto': fullScreen,
          'h-full': fullScreen,
          'max-h-max': fullScreen,
          'max-h-[80vh]': !fullScreen,
        }),
      }}
    >
      {children}
    </DialogContent>
  );
};

export const ModalFooter = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return <DialogActions className={className}>{children}</DialogActions>;
};

type ModalProps = {
  'open': boolean;
  'toggle'?: () => void;
  'data-testid'?: string;
  'fullScreen'?: boolean;
  'maxWidth'?: Breakpoint;
};

export default function Modal({
  open,
  toggle,
  children,
  'data-testid': dataTestId,
  fullScreen,
  maxWidth,
}: PropsWithChildren<ModalProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen && isMobile}
      data-testid={dataTestId}
    >
      {children}
    </Dialog>
  );
}
