import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import logger from '@app/utils/datadog-logger';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { PropsWithChildren, ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

export type ProductNudgeVariant = 'pro_tip' | 'product_tour';

const VARIANT_LABEL: Record<ProductNudgeVariant, string> = {
  pro_tip: 'Pro Tip',
  product_tour: 'Product Tour',
};

export interface ProductNudgeBannerProps {
  bannerKey: string;
  variant: ProductNudgeVariant;
  icon?: IconProp;
  iconImg?: React.ReactNode;
  onClick?: () => void;
  actionText?: string;
  onLinkClick?: () => void;
  linkText?: string;
  nonDismissable?: boolean;
}

export const ProductNudgeBanner = ({
  bannerKey,
  variant = 'pro_tip',
  onClick,
  actionText,
  onLinkClick,
  linkText,
  nonDismissable = false,
  icon,
  iconImg,
  children,
}: PropsWithChildren<ProductNudgeBannerProps>): ReactElement | null => {
  const { dismissed, loading, setDismissed } = useEventDismissed(bannerKey);

  const { hideDismissableBanners } = useThemeConfiguration();
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '50%',
    onChange: (inView) => {
      if (inView) {
        logger.info('banner-shown', { key: bannerKey });
      }
    },
  });

  if (loading || dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="bg-blue3 bg-product-nudge-bg-mobile md:bg-product-nudge-bg-desktop relative w-full rounded-md bg-[length:auto_100%] bg-right bg-no-repeat px-4 py-4 text-sm font-normal"
    >
      <div className="flex flex-row justify-start xl:items-center">
        <div className="mr-4 flex w-11 shrink-0 grow-0 justify-center align-middle">
          {iconImg ? (
            <div className="h-11 w-11">{iconImg}</div>
          ) : icon ? (
            <FontAwesomeIcon icon={icon} className="my-auto h-6 w-6 text-white" />
          ) : (
            <div className="h-6 rounded border border-white px-2 py-1 text-xs uppercase text-white">
              New
            </div>
          )}
        </div>

        <div className="flex flex-col justify-between gap-1 text-white xl:w-full xl:flex-row">
          <div className="flex flex-col">
            {actionText && onClick && (
              <div className="text-[10px] uppercase tracking-[.8px] text-gray-300">
                {VARIANT_LABEL[variant]}
              </div>
            )}
            <div className="text-left">{children}</div>
          </div>

          <div className="mt-2 flex flex-row gap-4 xl:mt-0">
            {actionText && onClick && (
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  logger.info('banner-cta-clicked', { key: bannerKey });
                  onClick();
                }}
                className="h-10 bg-white bg-opacity-90 hover:bg-white hover:bg-opacity-90"
              >
                <span className="text-black0">{actionText}</span>
              </Button>
            )}

            {linkText && onLinkClick && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  logger.info('banner-link-clicked', { key: bannerKey });
                  onLinkClick();
                }}
                className="mr-2 bg-opacity-90 text-white decoration-white hover:bg-opacity-90 hover:text-white"
              >
                {linkText}
              </Link>
            )}

            {!nonDismissable && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  logger.info('banner-dismissed', { key: bannerKey });
                  setDismissed();
                }}
                className="mr-2 bg-opacity-90 text-white decoration-white hover:bg-opacity-90 hover:text-white"
              >
                Dismiss
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
