import { EndpointResponse, PaginatedEndpointResponse } from '@app/@types/api.types';
import { CustomerData } from '@app/@types/customer.types';
import { ErrorNotification } from '@app/components/layout';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { ACCOUNT_SETUP_FEE_DESCRIPTION } from '@app/constants/unlimited';
import useShippingDetails from '@app/hooks/prepaid/use-shipping-details';
import useShippingAddress from '@app/hooks/use-shipping-address';
import { useIssuingDetails } from '@app/hooks/useIssuingDetails';
import { AddFundsModalDataWrapper } from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import { FetcherKey, apiGetFetcher, apiPostFetcher, getFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { faCube } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { TopUpTransaction } from '../../PrepaidAccountOverview/RecentTopUps';

type ActivationOptionRecord = {
  id: string;
  cost: string;
  description: string;
  discount_cents: string;
  discount_description: string;
  premium_included: boolean;
};

const ActivationOption = ({
  option,
  selected,
  selectOption,
  navigateToPremium,
}: {
  option: ActivationOptionRecord;
  selected: boolean;
  selectOption: () => void;
  navigateToPremium: () => void;
}) => (
  <button
    className={classNames('flex w-full flex-col rounded-md text-left', {
      'border-2 border-black': selected,
      'border border-gray-300 pb-[1px] pt-[1px]': !selected,
    })}
    onClick={() => selectOption()}
  >
    <div className="w-full p-3">
      <div className="flex items-center md:flex-col md:items-start">
        <h3 className="mr-2 text-2xl font-bold">{option.cost}</h3>
        <div className="text-sm text-black">{option.description}</div>
      </div>
    </div>
    <div className="flex w-full flex-col items-start gap-2 border-t border-gray-300 p-2 empty:hidden">
      {parseInt(option.discount_cents) !== 0 && (
        <>
          <div className="text-sm font-bold">{option.discount_cents}¢ Discount Boost</div>
          <div className="text-sm ">{option.discount_description}</div>
        </>
      )}
      {option.premium_included && (
        <div>
          <div className="text-sm font-bold">
            Free Premium{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
              onClick={navigateToPremium}
            >
              Features
            </a>
          </div>
          <div className="text-sm">3 months</div>
          <div className="mt-2 text-xs italic">
            Includes enhanced fraud prevention, spend controls, and 24/7 priority support
          </div>
        </div>
      )}
    </div>
  </button>
);

const ActivationOptions = ({
  options,
  selectedOptionId,
  setSelectedOptionId,
}: {
  options: ActivationOptionRecord[];
  selectedOptionId: string;
  setSelectedOptionId: (id: string) => void;
}) => {
  useEffect(() => {
    if (options.length === 1) {
      setSelectedOptionId(options[0].id);
    }
  }, [options, selectedOptionId, setSelectedOptionId]);
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col items-stretch justify-between gap-4 md:flex-row">
        {options.map((option) => {
          return (
            <ActivationOption
              key={option.id}
              selected={selectedOptionId === option.id}
              option={option}
              selectOption={() => setSelectedOptionId(option.id)}
              navigateToPremium={() => navigate('/premium')}
            />
          );
        })}
      </div>
    </div>
  );
};

export type PromotionResponse = {
  id: string;
  attributes: {
    details: {
      fee_amount_cents: number;
      discount_boost_per_gallon_cents: string;
      discount_description: string;
      discount_boost_duration_weeks: string;
      premium_included: boolean;
    };
  };
};

export type AcceptedPromotionsResponse = Array<{
  id: string;
  workflow_state: string;
  details: Record<string, unknown>;
  promotion_type: string;
}>;

export const hasAcceptedAccountSetupFeePromotion = (
  acceptedPromotions: AcceptedPromotionsResponse | undefined,
): boolean => {
  return (
    acceptedPromotions != undefined &&
    acceptedPromotions.some((promotion) => promotion.workflow_state === 'active')
  );
};

export const SetupPromotionsModal = ({
  customer,
  onClose,
}: {
  customer: CustomerData;
  onClose: () => void;
}): ReactElement | null => {
  const shippingAddress = useShippingAddress(customer);
  const { data: recentTopups } = useSWR<PaginatedEndpointResponse<TopUpTransaction>>(
    { url: `/treasury/transactions/recent_topups` },
    apiGetFetcher,
  );
  const { cardsDelivered, cardsOrdered } = useShippingDetails(recentTopups?.data ?? []);

  const { data: promotionsResponse, isLoading: isAllLoading } = useSWR<
    EndpointResponse<PromotionResponse[]>
  >(
    {
      url: '/treasury/account_promotions?account_setup_fee=true',
    },
    getFetcher,
  );
  const { data: acceptedPromotionData, isLoading: isAcceptedLoading } = useSWR<
    EndpointResponse<AcceptedPromotionsResponse>
  >(
    {
      url: '/treasury/account_promotions/accepted_promotions?account_setup_fee=true',
    },
    apiGetFetcher,
  );
  const acceptedPromotion = hasAcceptedAccountSetupFeePromotion(acceptedPromotionData?.data)
    ? 'active'
    : 'inactive';

  const [modal, setModal] = useState<'addFunds' | 'newUser' | null>(null);
  const [activationOptionId, setActivationOptionId] = useState(null);

  // If BYOC / KYC is incomplete, don't show the modal until afterwards.
  const { issuingOnboardingIncomplete } = useIssuingDetails();

  useEffect(() => {
    if (issuingOnboardingIncomplete || cardsDelivered || cardsOrdered) {
      setModal(null);
      onClose();
    } else if (acceptedPromotion === 'active') {
      setModal('addFunds');
    } else {
      setModal('newUser');
    }
  }, [acceptedPromotion, cardsOrdered, cardsDelivered, issuingOnboardingIncomplete, onClose]);

  const [hasError, setHasError] = useState(false);

  const { trigger: selectPromotion, isMutating } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey,
    { account_promotion_id: string }
  >(
    {
      url: '/treasury/account_promotions/accept',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        setHasError(true);
        if (guardAxiosError(e)) {
          logger.error('Error accepting promotion', e);
        }
      },
    },
  );

  const setupPromotions =
    promotionsResponse?.data
      ?.map((promotion: PromotionResponse) => ({
        id: promotion.id,
        fee_amount_cents: promotion.attributes.details.fee_amount_cents,
        cost: FormatCurrency({
          value: promotion.attributes.details.fee_amount_cents,
          options: { fromCents: true },
        }),
        description: 'One-time account setup fee',
        discount_cents: promotion.attributes.details.discount_boost_per_gallon_cents,
        discount_description: promotion.attributes.details.discount_description,
        premium_included: promotion.attributes.details.premium_included,
      }))
      .sort((a, b) => {
        return a.fee_amount_cents > b.fee_amount_cents ? 1 : -1;
      }) || [];

  if (setupPromotions.length === 0) {
    return null;
  }

  if (modal === 'newUser') {
    const submitButton = (
      <LoadingButton
        className="w-full"
        color="primary"
        disabled={activationOptionId === null}
        loading={isMutating}
        onClick={async () => {
          if (activationOptionId != null) {
            await selectPromotion({ account_promotion_id: activationOptionId });
            setModal('addFunds');
          }
        }}
      >
        <span>{activationOptionId === null ? 'Select an Option' : 'Fund my Wallet'}</span>
      </LoadingButton>
    );

    if (isAllLoading || isAcceptedLoading) {
      return null;
    }

    return (
      <Modal
        open={modal === 'newUser'}
        toggle={() => {
          setModal(null);
          onClose();
        }}
      >
        <div className="mb-3 px-2 pt-2">
          <ModalHeader
            title="Get Your Cards"
            onClose={() => {
              setModal(null);
              onClose();
            }}
          />
          <ModalBodyContent>
            {hasError && (
              <ErrorNotification error="There was an error accepting this option. Please try again." />
            )}
            <div className="flex flex-col gap-8 text-base text-gray-700">
              <div className="text-sm">
                To get your{' '}
                <a
                  className="font-bold"
                  href="https://www.atob.com/unlimited"
                  target="_blank"
                  rel="noreferrer"
                >
                  AtoB Unlimited
                </a>
                {'* '}
                fuel cards shipped,{' '}
                {setupPromotions.length > 1
                  ? 'choose from the options below and make a payment '
                  : 'make a payment '}{' '}
                by adding the one-time setup fee amount to your Wallet:
              </div>
              <ActivationOptions
                options={setupPromotions}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                selectedOptionId={activationOptionId}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                setSelectedOptionId={setActivationOptionId}
              />
              <div className="flex flex-col rounded-md bg-gray-200 p-3">
                <div className="font-bold md:text-lg ">Unlock average discounts of 42¢ off/gal</div>
                <div className="text-sm">at the stations you fuel the most</div>
                <div className="mb-1 mt-4 flex max-h-12 w-full flex-row justify-between space-x-3 md:items-stretch md:justify-between">
                  <img
                    className="grow rounded-md bg-white p-1.5 md:p-3"
                    src="/images/ta-petro-promo-lg.svg"
                  />
                  <img
                    className="grow rounded-md bg-white p-1.5 md:p-3"
                    src="/images/7-fleet-promo-lg.svg"
                  />
                  <img
                    className="grow rounded-md bg-white p-1.5 max-md:hidden md:p-3"
                    src="/images/chevron-promo-lg.svg"
                  />
                  <img
                    className="rounded-md bg-white p-1.5 md:hidden"
                    src="/images/chevron-promo-sm.svg"
                  />
                </div>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faCube} size="2x" className="scale-75 text-black" />
                <div>
                  <div className="text-sm font-bold">Your cards will be shipped to:</div>
                  <div className="text-sm">{shippingAddress}</div>
                </div>
              </div>
            </div>
          </ModalBodyContent>
        </div>
        <div className="mb-8 flex flex-col gap-8 px-8">
          <div className="hidden justify-center md:flex md:justify-end">{submitButton}</div>
          <div className="flex flex-col gap-y-2 text-xs text-gray-400">
            <div>
              *AtoB Unlimited is a <b>prepaid fuel card</b> where you build business credit with
              every purchase and can manage all of your expenses in one place.
            </div>
            <div>
              This setup fee is <b>non-refundable</b> and covers the cost of printing, shipping and
              activating your fuel cards.
            </div>
          </div>
        </div>
        <StickyButtonWrapper>{submitButton}</StickyButtonWrapper>
      </Modal>
    );
  }

  if (modal === 'addFunds') {
    const depositAmount = setupPromotions.find((option) => option.id === activationOptionId)
      ?.fee_amount_cents;
    return (
      <AddFundsModalDataWrapper
        customerCompanyName={customer.company_name}
        customerCompanyAddress={customer.company_address}
        onAddFunds={() => {
          window.location.href = '/';
        }}
        modalActive={modal === 'addFunds'}
        setModalActive={(isOpen) => {
          if (!isOpen) {
            onClose();
          }
          modal === 'addFunds' && setModal(null);
        }}
        minimumDepositAmountCents={depositAmount}
        initialAmount={depositAmount}
        initialDescription={ACCOUNT_SETUP_FEE_DESCRIPTION}
        scenario={'account_setup_fee'}
      />
    );
  }

  return null;
};
