import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import { AutoTopUpUpsell } from '@app/components/Prepaid/AutoTopUps/AutoTopUpUpsell';
import { SuggestedAmounts } from '@app/components/Prepaid/SuggestedAmounts';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import StripeElementsWrapper from '@app/components/StripeElements/StripeElementsWrapper';
import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import CustomerContext from '@app/contexts/customerContext';
import StripeContext from '@app/contexts/stripeContext';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { convertAmountToCents } from '@app/hooks/use-amount-cents';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import {
  convertCentsToDollars,
  getCurrencyFromCents,
} from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import {
  faCreditCard,
  faMoneyBillTransfer,
  faUniversity,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import axios from 'axios';
import { capitalize } from 'lodash-es';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Confirmation from './Confirmation';
import { transferButtonClasses } from './TransferSelector';
import WalletAccountFunds from './WalletAccountFunds';
import WalletPaymentMethods from './WalletPaymentMethods';
import type { AddressType } from '@app/@types/customer.types';

export type AddFundsScenario = 'add_funds' | 'account_setup_fee' | 'security_deposit';

export const addFundsScenarioToEventName: Record<AddFundsScenario, CustomerOnboardingEventName> = {
  add_funds: CustomerOnboardingEventName.CUSTOMER_ADD_FUNDS,
  account_setup_fee: CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE,
  security_deposit: CustomerOnboardingEventName.CUSTOMER_SECURITY_DEPOSIT_PAID,
};

export default function AddFundsModal({
  open,
  reset,
  onAddFunds,
  accountNumber,
  routingNumber,
  customerCompanyName,
  customerCompanyAddress,
  minimumDepositAmountCents,
  initialAmount,
  initialDescription,
  scenario,
}: {
  open: boolean;
  reset: () => void;
  onAddFunds: () => void;
  accountNumber: string | null;
  routingNumber: string | null;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  minimumDepositAmountCents?: number;
  initialAmount?: number;
  initialDescription?: string;
  scenario: AddFundsScenario;
}): ReactElement {
  const [callAddFundsOnClose, setCallAddFundsOnClose] = useState(false);
  const onSuccessfulPayment = () => {
    setCallAddFundsOnClose(true);
  };
  const onToggle = () => {
    if (callAddFundsOnClose) {
      onAddFunds();
    }
    reset();
  };

  const modalTitles: Record<AddFundsScenario, string> = {
    add_funds: 'Add Funds',
    account_setup_fee: 'Pay Your Fee',
    security_deposit: 'Security Deposit Payment',
  };

  return (
    <Modal open={open} toggle={onToggle}>
      <ModalHeader title={modalTitles[scenario]} onClose={onToggle} />
      <ModalBodyContent>
        <WalletPaymentMethods>
          {({ inboundBankAccounts, loading, fetchPaymentMethods, debitCards }) => (
            <AddFundsForm
              debitCards={debitCards}
              bankAccounts={inboundBankAccounts}
              reset={onToggle}
              onSuccessfulPayment={onSuccessfulPayment}
              loadingAccounts={loading}
              fetchPaymentMethods={fetchPaymentMethods}
              accountNumber={accountNumber}
              routingNumber={routingNumber}
              customerCompanyName={customerCompanyName}
              customerCompanyAddress={customerCompanyAddress}
              minimumDepositAmountCents={minimumDepositAmountCents}
              initialAmount={initialAmount}
              initialDescription={initialDescription}
              scenario={scenario}
            />
          )}
        </WalletPaymentMethods>
      </ModalBodyContent>
    </Modal>
  );
}

type FormFields = {
  amount: string;
  description: string;
};

type FormErrors = {
  amount?: string;
  account?: string;
  description?: string;
};

const validate = (
  formFields: FormFields,
  selectedAccountId: string | null,
  minimumDepositAmount: number,
  setErrors: (errors: FormErrors) => void,
) => {
  const { amount } = formFields;

  const errors: FormErrors = {
    amount:
      minimumDepositAmount > convertAmountToCents(amount)
        ? `Amount must be at least $${
            // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
            convertCentsToDollars({ value: minimumDepositAmount })
          }`
        : undefined,
    account: selectedAccountId == null ? 'Please select an account' : undefined,
  };

  setErrors(errors);

  if (Object.entries(errors).filter(([_key, value]) => value != null).length > 0) {
    return !!errors;
  }
};

const AddFundsTransferOptions = ({
  transferType,
  setTransferType,
  accountNumber,
  routingNumber,
  sameDayAchEnabled,
}: {
  transferType: 'ach' | 'wire' | 'debit';
  setTransferType: (transferType: 'ach' | 'wire' | 'debit') => void;
  accountNumber: string | null;
  routingNumber: string | null;
  sameDayAchEnabled: boolean;
}): ReactElement => {
  return (
    <div className="flex w-full flex-col">
      <button
        type="button"
        className={transferButtonClasses(transferType === 'debit')}
        key="debit"
        onClick={() => setTransferType('debit')}
      >
        <FontAwesomeIcon icon={faCreditCard} />
        <div className="flex flex-col items-start pl-6">
          <div className="font-bold">Debit Card</div>
          <div className="text-sm font-light">Instant</div>
        </div>
      </button>
      <button
        type="button"
        className={transferButtonClasses(transferType === 'ach')}
        key="ach"
        onClick={() => setTransferType('ach')}
      >
        <FontAwesomeIcon icon={faUniversity} />
        <div className="flex flex-col items-start pl-6">
          <div className="font-bold">Bank Account</div>
          <div className="text-sm font-light">
            ACH Transfer, {sameDayAchEnabled ? 'by next day' : 'in 3-5 days'}
          </div>
        </div>
      </button>
      {accountNumber && routingNumber && (
        <button
          type="button"
          className={transferButtonClasses(transferType === 'wire')}
          key="wire"
          onClick={() => setTransferType('wire')}
        >
          <FontAwesomeIcon icon={faMoneyBillTransfer} />
          <div className="flex flex-col items-start pl-6">
            <div className="font-bold">Wire Transfer</div>
            <div className="text-sm font-light">
              {transferType === 'wire' ? 'Instructions below' : 'Click for instructions'}
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

const WireInstructions = ({
  accountNumber,
  routingNumber,
  customerCompanyName,
  customerCompanyAddress,
}: {
  accountNumber: string;
  routingNumber: string;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
}): ReactElement => {
  return (
    <div className="mt-4 flex w-full flex-col justify-between text-[14px]">
      <div className="font-bold">Sending a wire transfer to your AtoB Wallet</div>
      <div className="mb-5 flex">
        Please use these instructions for any wires within the United States. You will need to
        provide this information to the bank that is sending the wire to your AtoB Wallet account.
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Account number:</div>{' '}
        <div className="ml-1 font-bold">{accountNumber}</div>
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Routing number:</div>{' '}
        <div className="ml-1 font-bold">{routingNumber}</div>
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Beneficiary name:</div>{' '}
        <div className="ml-1 font-bold">{customerCompanyName}</div>
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Beneficiary address:</div>{' '}
        <div className="ml-1 font-bold">
          {customerCompanyAddress.address1}
          <br />
          {customerCompanyAddress.address2 && (
            <>
              {customerCompanyAddress.address2}
              <br />
            </>
          )}
          {customerCompanyAddress.city}, {customerCompanyAddress.state} {customerCompanyAddress.zip}
          <br />
        </div>
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Bank name:</div>{' '}
        <div className="ml-1 font-bold">Evolve Bank & Trust</div>
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Bank address:</div>
        <div className="ml-1 max-w-[200px] font-bold">
          <div>6070 Poplar Avenue</div>
          <div>Suite 200</div>
          <div>Memphis, Tennessee 38119</div>
        </div>
      </div>
      <div className="mb-3 flex">
        <div className="w-[200px]">Bank phone number:</div>{' '}
        <div className="ml-1 font-bold">901-624-5500</div>
      </div>
    </div>
  );
};

const createReadableError = (error: unknown) => {
  Sentry.captureException(error);
  const defaultError = 'Something went wrong. Please try again.';
  const isStripeError = (error: unknown): error is StripeError => {
    return (error as StripeError).type !== undefined;
  };

  if (!isStripeError(error)) {
    if (!guardAxiosError(error)) {
      return defaultError;
    } else {
      return `There was an error: ${error?.response?.data?.errors?.[0] || defaultError}`;
    }
  }
  return createReadableStripeError(error);
};

const createReadableStripeError = (error: unknown) => {
  const stripeError = error as StripeError;
  if (stripeError.type !== 'card_error') {
    return stripeError.message ?? 'Something went wrong, please contact support.';
  }
  switch (stripeError.code) {
    case 'card_declined':
      return 'Payment declined by the issuer.';
    case 'expired_card':
      return 'This card is expired. Please add another one and try again.';
    default:
      return 'This card cannot be used. Please add another one and try again.';
  }
};

export const AddFundsForm = ({
  reset,
  onSuccessfulPayment,
  bankAccounts,
  debitCards,
  loadingAccounts,
  fetchPaymentMethods,
  accountNumber,
  routingNumber,
  customerCompanyName,
  customerCompanyAddress,
  minimumDepositAmountCents,
  initialAmount,
  initialDescription,
  scenario,
}: {
  reset: () => void;
  onSuccessfulPayment: () => void;
  bankAccounts: BankAccountPaymentMethodCombined[];
  debitCards: DebitCardPaymentMethodCombined[];
  loadingAccounts: boolean;
  fetchPaymentMethods: (isInbound: boolean) => Promise<void>;
  accountNumber: string | null;
  routingNumber: string | null;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  minimumDepositAmountCents?: number;
  initialAmount?: number;
  initialDescription?: string;
  scenario: AddFundsScenario;
}) => {
  const [idempotencyKey] = useState(uuid());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({} as FormErrors);
  const [overallError, setOverallError] = useState<string | null>(null);
  const [amountToAddCents, setAmountToAddCents] = useState(initialAmount ?? 0);
  const [editing, setEditing] = useState(true);
  const { clientSecret, setClientSecret } = useContext(StripeContext);
  const { supportEmailAddress } = useChannelPartner();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();
  const resetModal = () => {
    setClientSecret(null);
    reset();
  };

  const amountFixedFor: Record<AddFundsScenario, boolean> = {
    add_funds: false,
    account_setup_fee: true,
    security_deposit: true,
  };

  const minimumDepositAmountContextValue =
    useContext(CustomerContext)?.customer?.treasury?.minimum_deposit_amount.cents || 2500;
  const minimumDepositAmount = minimumDepositAmountCents || minimumDepositAmountContextValue;

  const [SAME_DAY_ACH_ENABLED] = useFeatureFlags('same_day_ach_enabled');
  const [ACH_ENABLED] = useProduct('treasury_ach_debit');

  const initialBankAccountId = bankAccounts[0]?.id;
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(initialBankAccountId);
  const setSelectedAccount = (_: 'inbound' | 'outbound', accountId: string | null) => {
    setSelectedAccountId(accountId);
  };
  const initialDebitCardId = debitCards[0]?.id;
  const [selectedDebitCardId, setSelectedDebitCardId] = useState<string | null>(initialDebitCardId);
  useEffect(() => {
    if (selectedAccountId === undefined) {
      setSelectedAccountId(initialBankAccountId);
    }

    if (selectedDebitCardId === undefined) {
      setSelectedDebitCardId(initialDebitCardId);
    }
  }, [initialBankAccountId, selectedAccountId, initialDebitCardId, selectedDebitCardId]);

  const [transferType, setTransferType] = useState<'ach' | 'wire' | 'debit'>('debit');
  const isDebit = transferType === 'debit';

  const [formFields, setFormFields] = useState({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    amount: getCurrencyFromCents(initialAmount).replace('$', '') ?? '',
    description: initialDescription ?? '',
  });
  const [shouldShowAddDebitCard, setShouldShowAddDebitCard] = useState(false);

  const setAmount = (value: string) => {
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isNotEmpty = value !== '';

    if (isNotANumber && isNotEmpty) {
      return;
    }

    setFormFields({ ...formFields, amount: value });
    setAmountToAddCents(convertAmountToCents(value));
  };

  const onSuccess = () => {
    setSuccess(true);
    setFormFields({
      amount: '',
      description: '',
    });

    createCustomerOnboardingEvent({
      customer_onboarding_event: { name: addFundsScenarioToEventName[scenario] },
    });

    onSuccessfulPayment();
  };

  const onFailure = (e: unknown) => {
    logger.error('Error adding funds', { error: e });
    setEditing(true);
    // Currently, we are unexpectedly confirming the card payment twice, which
    // is causing the real payment intent error to be clobbered. Only set one
    // error at a time until this is fixed.
    if (!overallError) {
      const readableError = createReadableError(e);
      if (readableError) {
        setOverallError(readableError);
      }
    }
    setLoading(false);
    setClientSecret(null);
  };

  const submitFunds = async () => {
    const validationErrors = validate(
      formFields,
      isDebit ? selectedDebitCardId : selectedAccountId,
      minimumDepositAmount,
      setErrors,
    );
    if (validationErrors) {
      if (transferType === 'debit' && debitCards.length === 0) {
        setShouldShowAddDebitCard(true);
      }
      return;
    }

    if (editing) {
      setEditing(false);
      return;
    }

    setLoading(true);
    setOverallError(null);
    try {
      const paymentIntentId = await axios.post('/treasury/financial_account/deposit', {
        idempotency_key: idempotencyKey,
        amount: amountToAddCents,
        description: formFields.description,
        payment_method_id: isDebit ? selectedDebitCardId : selectedAccountId,
      });
      const paymentIntentClientSecret = paymentIntentId?.data?.data?.attributes?.client_secret;
      if (paymentIntentClientSecret) {
        setClientSecret(paymentIntentClientSecret);
      } else {
        onSuccess();
      }
    } catch (e: unknown) {
      logger.error('Error adding funds', { error: e });
      onFailure(e);
    }
    setLoading(false);
  };

  if (clientSecret && !success) {
    return (
      <ConfirmFunds
        setLoading={setLoading}
        onSuccess={onSuccess}
        onFailure={onFailure}
        scenario={scenario}
      />
    );
  }

  if (success) {
    const closeButton = (
      <div className="flex w-full justify-end">
        <div>
          <Button color="primary" onClick={resetModal}>
            Close
          </Button>
        </div>
      </div>
    );

    switch (scenario) {
      case 'add_funds':
        return (
          <>
            <Success
              title="Success!"
              text="The funds you added should arrive within 1-2 minutes."
            />
            <AutoTopUpUpsell />
            {closeButton}
          </>
        );
      case 'account_setup_fee':
        return (
          <>
            <Success title="Your setup fee has been paid!" text="" />
            <div className="flex flex-col items-center px-6 pb-8 text-sm text-gray-700">
              <ul className="list-outside list-disc">
                <li className="py-1">We will collect the fee amount from your Wallet shortly.</li>
                <li className="py-1">
                  Your cards have been ordered and will deliver in 7-10 days.
                </li>
                <li className="py-1">
                  You will need to add additional funds into your Wallet before your first
                  transaction.
                </li>
              </ul>
            </div>
            {closeButton}
          </>
        );
      case 'security_deposit':
        return (
          <>
            <Success
              title={
                transferType == 'debit'
                  ? 'Your security deposit has been paid!'
                  : 'Your security deposit payment is on the way!'
              }
              text={
                transferType == 'debit'
                  ? 'Your credit line should now be active.'
                  : 'Your credit line will be activated once the deposit payment settles.'
              }
            />
            {closeButton}
          </>
        );
    }
  }

  const selectedFinancialInstitution = bankAccounts.find(
    (account) => account.id === selectedAccountId,
  );

  const selectedDebitCard = isDebit && debitCards.find((card) => card.id === selectedDebitCardId);

  const ConfirmationScreen = (): ReactElement => {
    if (isDebit) {
      return (
        <Confirmation
          flow="deposit"
          amount={convertCentsToDollars({ value: amountToAddCents }).format()}
          financialInstitution={`${capitalize(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            (selectedDebitCard as DebitCardPaymentMethodCombined)?.brand,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          )} ••••${(selectedDebitCard as DebitCardPaymentMethodCombined)?.last_four}`}
        />
      );
    }

    return (
      <Confirmation
        flow="deposit"
        amount={convertCentsToDollars({ value: amountToAddCents }).format()}
        financialInstitution={`${selectedFinancialInstitution?.name} ••${selectedFinancialInstitution?.mask}`}
        message={
          transferType === 'ach'
            ? `Funds should arrive ${
                SAME_DAY_ACH_ENABLED ? 'by the next business day' : 'between 3-5 business days'
              }  due to ACH processing times.`
            : ''
        }
      />
    );
  };

  if (shouldShowAddDebitCard) {
    return (
      <DebitCardModal
        isActive={shouldShowAddDebitCard}
        setIsActive={setShouldShowAddDebitCard}
        onSuccess={async () => {
          await fetchPaymentMethods(true);
          setShouldShowAddDebitCard(false);
        }}
      />
    );
  }

  return (
    <>
      {overallError && <ErrorNotification error={overallError} />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitFunds();
        }}
      >
        {editing ? (
          <>
            <AddFundsTransferOptions
              transferType={transferType}
              setTransferType={setTransferType}
              accountNumber={accountNumber}
              routingNumber={routingNumber}
              sameDayAchEnabled={SAME_DAY_ACH_ENABLED}
            />
            <div className="my-3" />
            <WalletAccountFunds
              transferType={transferType}
              bankAccounts={bankAccounts}
              loadingAccounts={loading || loadingAccounts}
              selectedAccountId={selectedAccountId}
              setSelectedAccount={setSelectedAccount}
              overallError={overallError}
              setOverallError={setOverallError}
              fetchPaymentMethods={fetchPaymentMethods}
              flow="inbound"
              debitCards={debitCards}
              selectedDebitCardId={selectedDebitCardId}
              setSelectedDebitCardId={setSelectedDebitCardId}
            />
            {transferType === 'wire' && accountNumber && routingNumber && (
              <WireInstructions
                accountNumber={accountNumber}
                routingNumber={routingNumber}
                customerCompanyName={customerCompanyName}
                customerCompanyAddress={customerCompanyAddress}
              />
            )}
            {transferType === 'ach' && !ACH_ENABLED && (
              <div className="mb-3 text-sm font-medium text-gray-700">
                Funding via bank account has not enabled for your account. Please reach out to us at{' '}
                <a className="underline" href={`mailto:${supportEmailAddress}`}>
                  {supportEmailAddress}
                </a>{' '}
                for support. In the meantime, you may add funds instantly using a debit card.
              </div>
            )}
            {((transferType === 'ach' && ACH_ENABLED) || isDebit) && (
              <>
                <FormElement
                  required={!amountFixedFor[scenario]}
                  error={errors.amount}
                  element={{
                    label: `Amount ${
                      amountFixedFor[scenario]
                        ? ''
                        : `(min $${
                            // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
                            convertCentsToDollars({
                              value: minimumDepositAmount,
                            })
                          })`
                    }`,
                    type: DataItemType.TEXT,
                    key: 'amount',
                  }}
                  handleOnChange={(value) =>
                    value !== null && setAmount(value.replace('$', '').trim())
                  }
                  value={`$${formFields.amount}`}
                  disabled={amountFixedFor[scenario]}
                />
                {!amountFixedFor[scenario] && <SuggestedAmounts setAmount={setAmount} />}
                <FormElement
                  element={{
                    label: 'Description',
                    type: DataItemType.TEXT,
                    key: 'description',
                  }}
                  handleOnChange={(value: string | null) => {
                    if (value && value.length > 120) {
                      setErrors({ description: 'Must be less than 120 characters.' });
                      return;
                    }
                    setErrors({ description: undefined });
                    setFormFields({ ...formFields, description: value ?? '' });
                  }}
                  error={errors.description}
                  value={formFields.description}
                  disabled={amountFixedFor[scenario]}
                />
              </>
            )}
          </>
        ) : (
          <>
            <ConfirmationScreen />
            {loading && (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            )}
          </>
        )}
        <div className="my-6 flex w-full pt-2 sm:justify-end">
          {!editing && (
            <div className="mr-3">
              <Button onClick={() => setEditing(true)} disabled={loading}>
                Cancel
              </Button>
            </div>
          )}
          {((transferType === 'ach' && ACH_ENABLED) || isDebit) && (
            <Button type="submit" color="primary" disabled={loading}>
              {
                {
                  add_funds: 'Add Funds',
                  account_setup_fee: 'Pay Account Setup Fee',
                  security_deposit: 'Pay Security Deposit',
                }[scenario]
              }
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

const ConfirmFunds = StripeElementsWrapper(
  ({
    onSuccess,
    onFailure,
    setLoading,
    scenario,
  }: {
    onSuccess: () => void;
    onFailure: (e: unknown) => void;
    setLoading: (loading: boolean) => void;
    scenario: AddFundsScenario;
  }): ReactElement => {
    const { clientSecret } = useContext(StripeContext);
    const stripe = useStripe();
    const [showRedirectionMessage, setShowRedirectionMessage] = useState(true);
    const isConfirmingRef = useRef(false);

    useEffect(() => {
      if (showRedirectionMessage) {
        return;
      }

      setLoading(true);
      const confirmFunds = async () => {
        try {
          const url = new URL(window.location.href);
          url.searchParams.set('payment_type', scenario);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const result = await stripe.confirmCardPayment(
            clientSecret,
            {
              return_url: url.toString(),
            },
            { handleActions: false },
          );
          const redirectUrl = result?.paymentIntent?.next_action?.redirect_to_url?.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          if (result.error) {
            onFailure(result.error);
            return;
          }

          if (result.paymentIntent.status === 'succeeded') {
            onSuccess();
          }
        } catch (e: unknown) {
          onFailure(e);
        }

        setLoading(false);
      };

      if (!clientSecret || !stripe) {
        return;
      }

      if (!isConfirmingRef.current) {
        isConfirmingRef.current = true;
        confirmFunds();
      }
    }, [clientSecret, stripe, showRedirectionMessage, onSuccess, onFailure, setLoading, scenario]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShowRedirectionMessage(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }, [showRedirectionMessage]);
    if (showRedirectionMessage) {
      return (
        <div>
          <div className="my-28 flex flex-col items-center text-center text-base font-medium">
            You should be redirected to confirm your payment in a few seconds.
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="mb-32 flex items-center text-base font-medium">
          <SpinnerBoundary />
        </div>
      </div>
    );
  },
  ApiEndpoints.PAYMENTS_ENDPOINTS.TREASURY_INTEGRATION_ENDPOINT,
);
