import { EndpointResponse } from '@app/@types/api.types';
import { FuelCardAccountData, FuelCardAccountDiscount } from '@app/@types/fuel-card-account.types';
import AtobAppIcon from '@app/assets/images/svg/atob_app_icon.svg';
import SavingsLogo from '@app/assets/images/svg/atob_savings.svg';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr/immutable';

type SavingsModalProps = {
  showSavingsModal: boolean;
  modalToggle: () => void;
  lifetimeDiscountAmount: string;
  lifetimeAverageDiscountPerGallon: string;
  discountMetadata?: FuelCardAccountDiscount;
};

export default function SavingsModal({
  showSavingsModal,
  modalToggle,
}: SavingsModalProps): ReactElement {
  const [streamlinedDriverAppOnboarding, blocklistDriverAppOnboarding] = useFeatureFlags(
    'streamlined_driver_app_onboarding',
    'blocklist_driver_app_onboarding',
  );

  const { isLoading: isFetching, data: fuelCardAccountData } = useSWR<
    EndpointResponse<FuelCardAccountData>
  >({ url: '/fuel_card_account?lifetime_discount=true' }, apiGetFetcher);

  const enableStreamlinedDriverAppOnboarding =
    streamlinedDriverAppOnboarding && !blocklistDriverAppOnboarding;

  return (
    <Modal open={showSavingsModal} toggle={modalToggle}>
      <ModalHeader title="Total Savings" onClose={modalToggle} />
      <ModalBodyContent>
        {isFetching && <SpinnerBoundary />}
        {!isFetching && fuelCardAccountData && (
          <SavingsModalBody
            fuelCardAccountData={fuelCardAccountData.data}
            enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
          />
        )}
      </ModalBodyContent>
    </Modal>
  );
}

function SavingsModalBody({
  fuelCardAccountData,
  enableStreamlinedDriverAppOnboarding,
}: {
  fuelCardAccountData: FuelCardAccountData;
  enableStreamlinedDriverAppOnboarding: boolean;
}) {
  const navigate = useNavigate();

  const { partnerName } = useChannelPartner();
  const headerText = useIsUberfreight(true)
    ? `Using your ${partnerName} card helps you save on every fuel purchase.`
    : `Using your AtoB card helps you save on every fuel purchase,
         with discounts on average of 42¢/gal at TA Petro, TA Express, 7-Eleven,
         Speedway, RaceTrac, Kwik Trip, Rutter's, Kum & Go, Allsup's, and more.`;

  return (
    <>
      <div className="pb-3">
        <p className="pb-4">{headerText}</p>
        <p className="pb-4">
          Find the best prices on fuel and access to exclusive discounts with the{' '}
          <Link to="/fuel-map" className="cursor-pointer text-blue-500 underline">
            Fuel Map
          </Link>
          .
        </p>
        <div className="flex gap-1">
          <div className="text-ds-green text-md flex items-center">
            <img src={SavingsLogo} className="pr-1" />
            Total AtoB Savings:
          </div>
          <p className="text-blue1 font-semibold">{fuelCardAccountData.lifetime_discount_amount}</p>
        </div>
      </div>
      {enableStreamlinedDriverAppOnboarding && (
        <div className="bg-green8 border-ds-green mb-5 mt-2 flex flex-row justify-between rounded-lg border p-2">
          <div className="flex flex-row gap-2">
            <img alt="AtoB App Icon" src={AtobAppIcon} />
            <div className="flex flex-col justify-center">
              <p className="text-base">
                {'Find the best prices on fuel and access exclusive discounts with the AtoB App. '}
                <span
                  onClick={() => navigate('/drivers')}
                  className="cursor-pointer text-base font-semibold underline"
                >
                  Invite your drivers to the mobile app
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
