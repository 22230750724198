import { EndpointResponse } from '@app/@types/api.types';
import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ModalCloseButton } from '@atob-developers/shared/src/components/Modal';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Drawer, Tab } from '@mui/material';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { RecipientData } from '../transfer.types';
import { TransferPanel } from './TransferPanel';
import {
  AccountType,
  FeeConfiguration,
  filterMethodsByTabValue,
  NormalizedDestination,
  PaymentMethodType,
  TabValue,
  WithdrawalState,
} from './utils';

const tabClasses = {
  root: 'p-0 normal-case items-start w-auto min-w-0 me-8 h-fit min-h-fit pb-3',
};

export const WithdrawFundsSidebarNew = ({
  open,
  reset,
  onCompletedTansfer,
  walletBalance,
}: {
  open: boolean;
  reset: () => void;
  onCompletedTansfer: () => void;
  walletBalance: string | null;
}) => {
  const [withdrawalState, setWithdrawalState] = useState<WithdrawalState>('initial');
  const [tabValue, setTabValue] = useState<TabValue>('own_transfer');
  const { data: transferMethodsData, isLoading: loadingMethods } = useSWR<
    EndpointResponse<FeeConfiguration[]>
  >({ url: `/treasury/financial_account/all_fees` }, apiGetFetcher);

  const { data: externalDestinationsData, isLoading: loadingExternalDestinations } = useSWR<
    EndpointResponse<RecipientData[]>
  >({ url: `/treasury/recipients` }, apiGetFetcher);

  const normalizedExternalDestinations: NormalizedDestination[] = useMemo(() => {
    if (!externalDestinationsData?.data) return [];

    return externalDestinationsData.data.map((destination) => ({
      id: destination.id,
      recipientType: 'external',
      name: destination.billing_details.name,
      type: 'bank_account',
      alternateName: destination.display_name,
      lastFour: destination.payment_details.bank_account.mask,
      supportedNetworks: destination.payment_details.bank_account
        .supported_networks as PaymentMethodType[],
      brand: null,
      recipientName: destination.billing_details.name,
    }));
  }, [externalDestinationsData]);

  const { data: ownDestinationsData, isLoading: loadingOwnDestinations } = usePaymentMethodsQuery();

  const normalizedOwnDestinations: NormalizedDestination[] = useMemo(() => {
    if (!ownDestinationsData?.data) return [];

    return ownDestinationsData.data
      .map((destination) => {
        const type = destination.payment_method_detail.type as AccountType;

        if (type === 'bank_account') {
          destination = destination as BankAccountPaymentMethodCombined;
          return {
            id: destination.id,
            recipientType: 'own',
            alternateName: null,
            name: destination.payment_method_detail.name,
            type: 'bank_account',
            lastFour: destination.payment_method_detail.mask,
            supportedNetworks: destination.payment_method_detail
              .supported_networks as PaymentMethodType[],
            brand: null,
            recipientName: null,
          };
        } else if (type === 'debit_card') {
          destination = destination as DebitCardPaymentMethodCombined;

          return {
            id: destination.id,
            recipientType: 'own',
            name: destination.payment_method_detail.description,
            alternateName: destination.payment_method_detail.expires,
            type: 'debit_card',
            lastFour: destination.payment_method_detail.last_four,
            brand: destination.payment_method_detail.brand,
            supportedNetworks: ['debit'],
            recipientName: null,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null) as NormalizedDestination[];
  }, [ownDestinationsData]);

  const resetAndClose = () => {
    setWithdrawalState('initial');
    setTabValue('own_transfer');

    reset();
  };

  return (
    <Drawer open={open} onClose={resetAndClose} anchor={'right'}>
      <div className="flex h-full w-screen flex-col gap-6 p-6 md:max-w-xl md:p-8">
        <div className="flex items-end justify-between">
          <h3 className="mt-1 text-xl font-semibold">Transfer Funds</h3>
          <div className="ml-3 flex h-7 items-center">
            <ModalCloseButton onClick={resetAndClose}></ModalCloseButton>
          </div>
        </div>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(_, value) => setTabValue(value)}
              classes={{ root: 'min-h-fit h-fit' }}
            >
              <Tab label="To myself" value="own_transfer" classes={tabClasses} />
              <Tab label="To someone else" value="external_transfer" classes={tabClasses} />
            </TabList>
          </Box>
          <TabPanel value="external_transfer" classes={{ root: 'p-0' }}>
            <TransferPanel
              availableMethods={filterMethodsByTabValue(transferMethodsData?.data || [], tabValue)}
              availableDestinations={normalizedExternalDestinations}
              loadingMethods={loadingMethods}
              loadingDestinations={loadingExternalDestinations}
              withdrawalState={withdrawalState}
              setWithdrawalState={setWithdrawalState}
              reset={resetAndClose}
              onCompletedTansfer={onCompletedTansfer}
              walletBalance={walletBalance}
            />
          </TabPanel>
          <TabPanel value="own_transfer" classes={{ root: 'p-0' }}>
            <TransferPanel
              availableMethods={filterMethodsByTabValue(transferMethodsData?.data || [], tabValue)}
              availableDestinations={normalizedOwnDestinations}
              loadingMethods={loadingMethods}
              loadingDestinations={loadingOwnDestinations}
              withdrawalState={withdrawalState}
              setWithdrawalState={setWithdrawalState}
              reset={resetAndClose}
              onCompletedTansfer={onCompletedTansfer}
              walletBalance={walletBalance}
            />
          </TabPanel>
        </TabContext>
      </div>
    </Drawer>
  );
};
