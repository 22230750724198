import { EndpointResponse, Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import { NotificationTypes } from '@app/@types/notification_setting.types';
import { TagData } from '@app/@types/tag.types';
import { UserData } from '@app/@types/users.types';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import { NotificationsList } from '@app/constants/notifications';
import {
  getUpdateUserNotificationSettingsParams,
  useUpdateUserNotificationSettings,
} from '@app/hooks/query/useNotificationSettings';
import { handleError } from '@app/utils/data/errorHandling';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { WithId } from '@atob-developers/shared/src/@types/form.types';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { Tooltip } from '@atob-developers/shared/src/components/Tooltip';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { formatInput } from '@atob-developers/shared/src/utils/FormatUtils';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';

import useSWR from 'swr';
import { NotificationsSection } from './NotificationsSection';
export interface UserSidebarProps {
  open: boolean;
  allowManageNotifications: boolean;
  setOpen: (open: boolean) => void;
  rolesEntry: Record<string, WithId<Record<string, string>>>;
  fetchData: () => void;
  workspaceRoles: boolean;
}

export const NewUserSidebar = ({
  open,
  allowManageNotifications,
  setOpen,
  rolesEntry,
  fetchData,
  workspaceRoles,
}: UserSidebarProps): ReactElement => {
  const { addToast } = useToasts();
  const [newUser, setNewUser] = useState<Partial<UserData>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { updateUserNotificationSettings } = useUpdateUserNotificationSettings();
  const [enabledNotifications, setEnabledNotifications] = useState<NotificationTypes[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>(['all']);
  const roleItems: WithId<Record<string, string>>[] = Object.values(rolesEntry);
  const {
    data: tags,
    isLoading: isTagsLoading,
    error: tagError,
  } = useSWR<PaginatedEndpointResponse<TagData>>({ url: '/tags?all=true' }, apiGetFetcher);
  const allResources = [
    {
      id: 'all',
      name: 'All tags',
    },
    ...((tags?.data ?? []).map((tag) => ({ id: tag.id.toString(), name: tag.name })) || []),
  ];

  const showNotificationsOptions = newUser?.role !== 'bookkeeper' && allowManageNotifications;

  // Based on role and notification visibility, enable all valid notifications
  useEffect(() => {
    // List of valid notification IDs that can be enabled
    const notificationIDs = NotificationsList.filter((n) => !n.globalOnly).map(
      (notification) => notification.id,
    );

    if (!showNotificationsOptions) {
      setEnabledNotifications([]);
    } else {
      setEnabledNotifications(notificationIDs);
    }
  }, [newUser, showNotificationsOptions]);

  const onSave = () => {
    setIsLoading(true);
    const successMsg = `We’ve emailed the user an invitation to join. ${
      newUser.phone
        ? 'To receive SMS notifications, the user must verify the phone number by clicking the SMS link sent to user’s phone.'
        : ''
    }`;
    const values = { ...newUser, phone: newUser.phone?.replace(/[() -]/g, '') };
    axios
      .post<EndpointResponse<Entity<UserData>>>('/users', { user: values })
      .then((response) => {
        if (!showNotificationsOptions) {
          return;
        }
        const user = deserialize(response.data).data;
        const params = getUpdateUserNotificationSettingsParams(
          user,
          selectedResources,
          enabledNotifications,
          allResources,
        );
        updateUserNotificationSettings(params);
      })
      .then(() => {
        fetchData();
        addToast(successMsg, {
          appearance: 'success',
        });
        setOpen(false);
      })
      .catch((res) => {
        handleError(res);
        if (res.response.status === 422 || res.response.status === 400) {
          if (res.response.data.errors.base) {
            addToast(res.response.data.errors.base, {
              appearance: 'error',
            });
          } else {
            const errors = res.response.data.errors;
            const errorMessages = Array.isArray(errors)
              ? errors
              : Object.keys(errors).map((key) => key + ' ' + errors[key][0]);
            addToast(errorMessages.join(', '), { appearance: 'error' });
          }
        } else {
          addToast('There was an error inviting this user. Please try again later', {
            appearance: 'error',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SidebarWrapper title="Add a new user" open={open} setOpen={(val: boolean) => setOpen(val)}>
      <SideBarBody>
        <div data-testid="user-sidebar-body" className="flex flex-col space-y-4 text-sm">
          <div className="w-full max-w-lg flex-grow">
            <TextField
              fullWidth
              id="name"
              size="small"
              label="Name"
              value={newUser.name}
              onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            />
          </div>
          <div>
            <div className="w-full max-w-lg flex-grow">
              <TextField
                fullWidth
                label={
                  <>
                    <span>Phone</span>
                    <span className="ml-2">
                      <Tooltip
                        icon={faExclamationCircle}
                        iconClassName="text-accent23"
                        title="Entered phone number requires verification before it can start receiving messages"
                      />
                    </span>
                  </>
                }
                id="phone"
                size="small"
                value={formatInput(newUser.phone || '', 'tel')}
                onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })}
              />
            </div>
          </div>
          <div className="w-full max-w-lg flex-grow">
            <TextField
              fullWidth
              id="email"
              size="small"
              label="Email"
              required
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </div>
          {workspaceRoles && (
            <div className="w-full max-w-lg flex-grow">
              <label className="label">Role</label>
              <Select
                value={newUser.role}
                className="my-2"
                fullWidth
                onChange={(e) => {
                  setNewUser((prevValues) => ({ ...prevValues, role: e.target.value }));
                }}
                renderValue={(value) => {
                  const item = roleItems.find((item) => item.id === value);
                  if (!item) {
                    return <span className="text-grey17 font-normal">Role</span>;
                  }
                  return (
                    <div className="flex w-full items-center justify-between text-gray-900">
                      {item.name}
                    </div>
                  );
                }}
                disabled={false}
                displayEmpty
              >
                {roleItems.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    classes={{ root: 'flex-col items-start' }}
                  >
                    <div className="flex cursor-pointer font-medium">{item.name}</div>
                    <div className="flex cursor-pointer text-xs text-gray-500">
                      {item.description}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {showNotificationsOptions && (
            <NotificationsSection
              isLoading={isTagsLoading || isLoading}
              error={tagError as AxiosError}
              allResources={allResources}
              userHasPhone={!!newUser.phone}
              selectedResources={selectedResources}
              enabledNotifications={enabledNotifications}
              setSelectedResources={setSelectedResources}
              setEnabledNotifications={setEnabledNotifications}
            />
          )}
        </div>
      </SideBarBody>
      <SideBarFooter>
        <Button
          size="small"
          disabled={isLoading}
          onClick={() => {
            onSave();
          }}
        >
          Add
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </SideBarFooter>
    </SidebarWrapper>
  );
};
