import {
  FormatCurrency,
  getCurrencyFromCents,
} from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { TransferSummary } from '../TransferSummary';
import {
  computeTotalFeeAmount,
  FeeConfiguration,
  feeString,
  NormalizedDestination,
} from '../utils';

export const ConfirmationModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
  onConfirm,
  loading,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee: FeeConfiguration;
  description: string;
  onConfirm: () => void;
  loading: boolean;
}) => {
  const destinationType = destination.type === 'debit_card' ? 'Card' : 'Account';
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const amountString = FormatCurrency({ value: amount, options: { fromCents: false } });
  const feeAmount = getCurrencyFromCents(
    computeTotalFeeAmount({ transferAmountCents, feeData: fee }),
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <h3>Confirm Withdrawal</h3>
        <p className="text-secondary text-sm font-normal">
          We will transfer an amount of <span className="text-primary">{amountString}</span> from
          your AtoB Wallet to the <span className="text-primary">•••• {destination.lastFour}</span>{' '}
          {destinationType.toLowerCase()}.
        </p>
      </DialogTitle>
      <DialogContent>
        <div className="mt-2 flex flex-col gap-6">
          <TransferSummary
            recipientName={destination.recipientName}
            destinationType={destinationType}
            lastFour={destination.lastFour}
            feeAmount={feeAmount}
            feeString={feeString(fee)}
            description={description}
            amount={transferAmountCents}
          />
          <div className="flex gap-3">
            <Button color="secondary" fullWidth onClick={onClose}>
              Back
            </Button>
            <LoadingButton color="primary" fullWidth onClick={onConfirm} loading={loading}>
              <span>Transfer {amountString}</span>
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
