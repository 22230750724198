import useCustomer from '@app/hooks/useCustomer';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import CurrencyFormat from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { CreditEnablementNetNewStepsProps } from './CreditEnablementNetNewStepsProps';

export const CreditEnablementNetNewOffer = ({
  toggle,
  onNext,
}: CreditEnablementNetNewStepsProps): ReactElement | null => {
  const { weekly_credit_limit } = useCustomer();
  const formattedOfferAmount = CurrencyFormat({
    value: weekly_credit_limit,
    onlyWholeNumber: true,
  });

  return (
    <>
      <ModalHeader
        title="You're approved for a credit line with Credit Enablement!"
        onClose={toggle}
      />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-base text-gray-700">
          <div className="mb-4 font-bold">Review Your Offer:</div>

          <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
            <div className="w-full border-b border-gray-300 p-4">
              <div className="flex items-center md:flex-col md:items-start">
                <h3 className="mr-2 text-4xl font-semibold">{formattedOfferAmount}</h3>
                <div className="mt-2 text-sm font-semibold text-black">
                  Weekly credit line. Billed weekly.
                </div>
              </div>
            </div>
            <div className="ml-4 flex flex-col items-start p-2">
              <ul className="list-disc p-2 pb-6 text-sm">
                <li>$75 one-time account setup fee</li>
                <li>
                  30¢ per gallon Credit Enablement surcharge for the first two months. Surcharge is
                  removed after two months of on-time bill payments.
                </li>
                <li>Must connect a primary bank account</li>
                <li>Offer expires in 30 days</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-4 mt-8 font-bold">What is Credit Enablement?</div>
            <div className="text-sm">
              Credit Enablement is a program that enables customers who normally wouldn’t qualify
              for credit to receive credit, with special terms & conditions.
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-center md:justify-end">
          <Button className="w-full sm:w-48" size="medium" onClick={onNext}>
            Continue With Offer
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
