import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { MobileNavHeaderMenuContext } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useIsNativeApp from '@app/hooks/useIsNativeApp';
import {
  faArrowLeft,
  faBars,
  faEllipsisVertical,
  faEllipsisH,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, ReactNode, useContext, useState } from 'react';

export default function MobileNavHeader({
  title,
  onBack,
  mobileStickyButton,
  unlockButton,
  searchBar,
}: {
  title: string | ReactNode;
  onBack?: (() => void) | null;
  mobileStickyButton?: ReactNode;
  unlockButton?: ReactNode;
  searchBar?: ReactNode;
}): ReactElement | null {
  const { setMobileNavOpen } = useContext(MobileNavHeaderMenuContext);
  const { menuOptions, prevButton, nextButton } = useContext(MobileNavHeaderMenuContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isNativeApp } = useIsNativeApp();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { showAppNav } = useThemeConfiguration();

  if (!showAppNav) {
    return null;
  }

  const backButton =
    onBack != null ? (
      <IconButton color="tertiary" onClick={onBack}>
        <FontAwesomeIcon icon={faArrowLeft} className="h-5 w-5" />
      </IconButton>
    ) : (
      !isNativeApp && (
        <IconButton color="tertiary" onClick={() => setMobileNavOpen(true)}>
          <FontAwesomeIcon icon={faBars} className="h-5 w-5" />
        </IconButton>
      )
    );

  const menu = menuOptions.length > 0 && (
    <>
      <IconButton color="tertiary" onClick={handleClick} id="nav-menu-button">
        <FontAwesomeIcon
          icon={isNativeApp ? faEllipsisH : faEllipsisVertical}
          className="h-5 w-5"
        />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'nav-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuOptions
          .sort((opt1, opt2) => opt1.order - opt2.order)
          .map((option, i) => {
            return [
              <MenuItem
                key={'item' + option.value}
                onClick={() => {
                  handleClose();
                  option.onClick();
                }}
              >
                {option.value}
              </MenuItem>,
              i < menuOptions.length - 1 && <Divider sx={{ my: 0.5 }} />,
            ];
          })}
      </Menu>
    </>
  );

  return (
    <>
      <div
        className={classNames(
          'z-nav-bar border-level-2 bg-level-0 sticky left-0 right-0 top-0 flex items-center border-b p-6 md:hidden',
          isNativeApp ? 'h-[42px] justify-center' : 'h-[72px] justify-between',
        )}
      >
        {isNativeApp ? (
          <>
            <div className="absolute left-0 pl-2">{backButton}</div>
            <h3 className="text-base font-medium tracking-[-0.5px]">{title}</h3>
            <div className="absolute right-0 pr-2">{unlockButton || menu}</div>
          </>
        ) : (
          <>
            <div className="flex items-center gap-3">
              {backButton}
              <h3 className="text-primary text-base font-semibold">{title}</h3>
            </div>
            <span>
              <span>
                {prevButton && (
                  <IconButton
                    color="tertiary"
                    disabled={prevButton.disabled}
                    onClick={prevButton.onClick}
                    id="nav-menu-button"
                  >
                    <FontAwesomeIcon icon={prevButton.icon} className="h-5 w-5" />
                  </IconButton>
                )}
                {nextButton && (
                  <IconButton
                    disabled={nextButton.disabled}
                    onClick={nextButton.onClick}
                    id="nav-menu-button"
                  >
                    <FontAwesomeIcon icon={nextButton.icon} className="h-5 w-5" />
                  </IconButton>
                )}
              </span>
              {menu}
            </span>
          </>
        )}
      </div>
      {searchBar && <div className="mx-4 md:hidden">{searchBar}</div>}
      {mobileStickyButton}
    </>
  );
}
