import { faBuildingColumns, faCheck, faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, MenuItem, Select } from '@mui/material';
import { SetStateAction } from 'react';
import { NormalizedDestination, PaymentMethodType } from '../utils';

const DestinationItem = ({
  destinationId,
  selected = false,
  availableDestinations,
}: {
  destinationId: string;
  selected?: boolean;
  availableDestinations: NormalizedDestination[];
}) => {
  const destination = availableDestinations.find((elem) => elem.id === destinationId);
  if (!destination) return null;

  return (
    <div className="flex w-full flex-grow items-center gap-2">
      <div className="flex w-full flex-wrap gap-2">
        <div className="flex items-center gap-2 text-sm">
          <FontAwesomeIcon
            icon={destination.type === 'debit_card' ? faCreditCard : faBuildingColumns}
          />
          <span>••••&nbsp;{destination.lastFour}</span>
        </div>
        <Chip
          color="grey"
          size="small"
          label={
            destination.name + (destination.alternateName ? ` (${destination.alternateName})` : '')
          }
        />
      </div>
      {selected && <FontAwesomeIcon icon={faCheck} className="text-secondary" />}
    </div>
  );
};

export const DestinationSelector = ({
  selectedDestination,
  availableDestinations,
  loadingDestinations,
  selectedMethod,
  setSelectedDestination,
}: {
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination | null;
  selectedMethod: PaymentMethodType | null;
  setSelectedDestination: (value: SetStateAction<NormalizedDestination | null>) => void;
  loadingDestinations: boolean;
}) => {
  return (
    <div>
      <h4 className="mb-2 font-medium">Destination Account</h4>
      <Select
        value={selectedDestination}
        onChange={(e) => {
          const value = e.target.value as string;
          if (value === 'ignore') return;
          const matchedDestination = availableDestinations.find((dest) => dest.id === value);
          if (matchedDestination) {
            setSelectedDestination(matchedDestination);
          }
        }}
        fullWidth
        displayEmpty
        disabled={loadingDestinations || availableDestinations.length === 0 || !selectedMethod}
        renderValue={(value) =>
          value !== null ? (
            <DestinationItem
              destinationId={value.id}
              availableDestinations={availableDestinations}
            />
          ) : loadingDestinations ? (
            'Loading'
          ) : availableDestinations.length === 0 && selectedMethod ? (
            'No destinations available'
          ) : (
            'Select destination'
          )
        }
      >
        {availableDestinations.map((destination) => {
          const id = destination.id;
          return (
            <MenuItem key={id} value={id}>
              <DestinationItem
                destinationId={id}
                selected={selectedDestination?.id === id}
                availableDestinations={availableDestinations}
              />
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};
