import { Coordinates, FuelGrade, FuelListing } from '@app/@types/fuel_listings.types';
import logger from '@app/utils/datadog-logger';
import {
  choosePreferredFuelPrice,
  getIsValidPrice,
  getReadableDistanceFromFuelListing,
} from '@app/utils/fuel-listings';
import { faLocationDotSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo } from 'react';
import FuelListingPriceWithDiscount from './FuelListingPriceWithDiscount';
import { BrandLogo } from './Logos/BrandLogo';
import Spinner from './Spinner';

dayjs.extend(relativeTime);

const LoadingComponent = () => (
  <div className="mt-24 h-[100vh] w-full rounded-lg text-center">
    <Spinner />
  </div>
);

export interface FuelListingsListProps {
  isFetching: boolean;
  originLocation: Coordinates | undefined;
  fuelListings: FuelListing[];
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  onSelect: (fuelListing: FuelListing | null) => void;
  showMore: (() => void) | null;
}

const FuelListingOverview = ({
  originLocation,
  fuelListing,
  onSelect,
  selectedGrade,
}: {
  originLocation: Coordinates | undefined;
  fuelListing: FuelListing;
  onSelect: (fuelListing: FuelListing) => void;
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
}) => {
  const distance = useMemo(() => {
    if (originLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(originLocation, fuelListing);
  }, [originLocation, fuelListing]);

  const priceToShow = choosePreferredFuelPrice(
    fuelListing.attributes.fuel_prices.data,
    selectedGrade,
  );

  const date = priceToShow.attributes.fuel_sign_time ?? '';

  const isGuaranteedDiscount = priceToShow.attributes.discount_type === 'RETAIL_MINUS';
  const isPriceValid = getIsValidPrice(priceToShow);

  return (
    <div
      className="bg-level-0 border-level-2 hover:bg-background cursor-pointer rounded border"
      onClick={() => onSelect(fuelListing)}
    >
      <div className="flex items-center gap-2 p-3">
        <div className="flex w-full items-center gap-2">
          <div className="h-10 w-10 items-center justify-center">
            <BrandLogo
              logoURL={fuelListing.attributes.logo_url}
              brand={fuelListing.attributes.location.name}
              size={40}
              rounded
              border
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-0.5 truncate">
            <p className="text-primary w-full truncate text-sm font-semibold">
              {fuelListing.attributes.location.name}
            </p>
            <p className="text-secondary flex w-full items-center gap-1 text-xs">
              <span>{distance}</span>

              {isPriceValid ? (
                <>
                  <div className="bg-level-3 h-1 w-1 rounded-full" />
                  <span className="truncate">Updated {dayjs(date).fromNow()}</span>
                </>
              ) : isGuaranteedDiscount || priceToShow.attributes.discount_per_gallon_cents === 0 ? (
                <>
                  <div className="bg-level-3 h-1 w-1 rounded-full" />
                  <span className="truncate">Price Unavailable</span>
                </>
              ) : null}
            </p>
          </div>
          <div className="flex shrink-0 grow flex-col items-end justify-center gap-0.5">
            <FuelListingPriceWithDiscount price={priceToShow} />
          </div>
        </div>
      </div>
    </div>
  );
};

const FuelListingsList = ({
  isFetching,
  originLocation,
  fuelListings,
  selectedGrade,
  onSelect,
  showMore,
}: FuelListingsListProps) => {
  if (isFetching) {
    return <LoadingComponent />;
  }

  return (
    <div className="flex h-auto flex-col gap-2 px-6 pb-2 sm:overflow-auto">
      {fuelListings.length ? (
        fuelListings.map((fuelListing: FuelListing) => (
          <FuelListingOverview
            key={fuelListing.id}
            originLocation={originLocation}
            fuelListing={fuelListing}
            onSelect={(listing) => {
              logger.info('fuel-listing-select', {
                from: 'list',
                id: listing.attributes.site_id,
              });
              onSelect(listing);
            }}
            selectedGrade={selectedGrade}
          />
        ))
      ) : (
        <div className="flex w-full flex-col items-center gap-2 p-8 font-medium text-gray-500">
          <FontAwesomeIcon className="mb-4" icon={faLocationDotSlash} size="3x" />
          <div className="text-md">Nothing in this area</div>
          <div className="text-xs">Try searching another area or applying different filters</div>
        </div>
      )}
      {showMore && fuelListings.length > 0 && !isFetching && (
        <Button color="secondary" size="small" disabled={isFetching} onClick={showMore}>
          Show More
        </Button>
      )}
    </div>
  );
};

export default FuelListingsList;
