import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import { SetStateAction } from 'react';
import { formatAmountString } from '../utils';

export const AmountInput = ({
  amount,
  setAmount,
  walletBalance,
}: {
  amount: string;
  setAmount: (value: SetStateAction<string>) => void;
  walletBalance: string | null;
}) => (
  <div>
    <h4 className="mb-2 font-medium">Amount</h4>
    <div className="flex flex-col gap-2">
      <TextField
        aria-errormessage='{"type":"error","message":"Amount is required"}'
        variant="outlined"
        fullWidth
        onChange={(e) => setAmount(formatAmountString(e.target.value))}
        value={amount}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
            </InputAdornment>
          ),
        }}
      />
      {walletBalance && (
        <div className="text-secondary text-sm">Current balance: {walletBalance}</div>
      )}
    </div>
  </div>
);
