import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, MenuItem, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { SetStateAction } from 'react';
import {
  FeeConfiguration,
  feeString,
  filterDestinationsBySelectedMethod,
  iconMapping,
  nameMapping,
  NormalizedDestination,
  PaymentMethodType,
  transferTimeMapping,
} from '../utils';

const PaymentMethodItem = ({
  name,
  selected = false,
  availableMethods,
}: {
  name: PaymentMethodType;
  selected?: boolean;
  availableMethods: FeeConfiguration[];
}) => {
  const matchingFee = availableMethods.find((method) => method.type === name);

  return (
    <div
      className={classNames(
        'border-level-2 flex w-full items-center gap-2 rounded border px-4 py-3 hover:border-[1.5px] hover:px-[15.5px] hover:py-[11.5px]',
        selected ? 'border-primary border-[1.5px] px-[15.5px] py-[11.5px]' : null,
      )}
    >
      <FontAwesomeIcon icon={iconMapping[name]} />
      <div className="flex flex-grow flex-wrap items-center gap-x-2">
        <div className="flex flex-grow gap-2">
          <div className="text-base font-medium">
            {[nameMapping[name], transferTimeMapping[name]].join(' ')}
          </div>
          {matchingFee && <Chip label={feeString(matchingFee)} size="small" color="grey" />}
        </div>
      </div>
    </div>
  );
};

const LoadingPaymentMethod = () => {
  return (
    <div className="border-level-2 flex h-fit w-full items-center gap-2 gap-2 rounded border px-4 py-3">
      <Skeleton width={16} height={16} variant="rectangular" />
      <Skeleton width={'100%'} />
    </div>
  );
};

export const PaymentMethodSelector = ({
  availableMethods,
  availableDestinations,
  selectedDestination,
  selectedMethod,
  setSelectedDestination,
  setSelectedMethod,
  loadingMethods,
}: {
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination | null;
  selectedMethod: PaymentMethodType | null;
  setSelectedDestination: (value: SetStateAction<NormalizedDestination | null>) => void;
  setSelectedMethod: (value: SetStateAction<PaymentMethodType | null>) => void;
  loadingMethods: boolean;
}) => {
  return (
    <div>
      <h4 className="mb-2 font-medium">Payment method</h4>
      <div className="flex flex-col gap-2">
        {availableMethods.length != 0 ? (
          availableMethods.map((method) => (
            <MenuItem
              key={method.type}
              value={method.type}
              onClick={() => {
                setSelectedMethod(method.type);
                if (
                  selectedDestination &&
                  !filterDestinationsBySelectedMethod(availableDestinations, method.type).includes(
                    selectedDestination,
                  )
                ) {
                  setSelectedDestination(null);
                }
              }}
              classes={{ root: 'p-0 rounded' }}
            >
              <PaymentMethodItem
                name={method.type}
                selected={selectedMethod === method.type}
                availableMethods={availableMethods}
              />
            </MenuItem>
          ))
        ) : loadingMethods ? (
          <LoadingPaymentMethod />
        ) : (
          <div>No payment methods available</div>
        )}
      </div>
    </div>
  );
};
