import CopyCheckedIcon from '@app/assets/images/svg/copy-checked.svg';
import CopyIcon from '@app/assets/images/svg/copy.svg';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

const displayNumber = (detailNumber: string | null, show: boolean, limit: number): string => {
  if (!detailNumber) return '';
  return show ? detailNumber : `${'****'.slice(-limit)} ${detailNumber.slice(-4)}`;
};

const WalletDetail = ({
  title,
  detailNumber,
  showNumber,
  setShowNumber,
  copyNumber,
  setCopyNumber,
  onCopyNumber,
  restricted,
}: {
  title: string;
  detailNumber: string | null;
  showNumber: boolean;
  setShowNumber?: (showNumber: boolean) => void;
  copyNumber?: boolean;
  setCopyNumber?: (copy: boolean) => void;
  onCopyNumber?: (number: string, setCopyNumber: (copy: boolean) => void) => void;
  restricted?: boolean;
}): ReactElement => {
  const { addToast } = useToasts();
  const limit = 4;
  const notAvailable = detailNumber == null;

  const handleCopyClick = () => {
    if (!notAvailable && !restricted) {
      if (!onCopyNumber || !setCopyNumber) return;
      addToast('Copied to clipboard!', { appearance: 'success' });
      return onCopyNumber(detailNumber, setCopyNumber);
    }
  };

  const handleShowNumberClick = () => {
    setShowNumber && setShowNumber(!showNumber);
  };

  return (
    <div className="bg-background mb-4 w-full flex-col rounded-md border px-5 py-4 font-normal md:items-center md:justify-between">
      <div className="flex">
        <div className="flex w-full flex-col md:flex-row md:justify-between">
          <span className="text-secondary mb-1 text-sm md:mb-3">
            {title} <span className="inline">number</span>
          </span>
          <div className="font-body text-primary text-md leading-sm mb-3 mr-2 min-w-[130px] font-semibold md:mb-0 md:text-right">
            {notAvailable ? 'Not available' : displayNumber(detailNumber, showNumber, limit)}
          </div>
        </div>
        {!notAvailable && !restricted && (
          <span onClick={handleCopyClick} className="cursor-pointer pt-0.5">
            {copyNumber ? (
              <img src={CopyCheckedIcon} alt="copy-checked" />
            ) : (
              <img src={CopyIcon} alt="copy" />
            )}
          </span>
        )}
      </div>
      {!notAvailable && !restricted && (
        <div className="flex">
          <button
            onClick={handleShowNumberClick}
            className="bg-accent-23 inline-flex w-full cursor-pointer items-center justify-center rounded px-4 py-3 text-center text-sm font-medium leading-4 text-black"
          >
            {showNumber ? (
              <>
                <FontAwesomeIcon icon={faEyeSlash} className="mr-2" /> <span>Hide</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faEye} className="mr-2" /> <span>Show</span>
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default WalletDetail;
