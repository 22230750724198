import AddressInput from '@app/components/elements/AddressInput';
import useWindowWidth from '@app/hooks/useWindowWidth';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { ReactElement } from 'react';
import * as Yup from 'yup';
import { CardRequestParams } from './CardOrderModal';
import type { AddressType } from '@app/@types/customer.types';

export const CardOrderAddress = ({
  params,
  setParams,
  onNext,
  onBack,
  closeModal,
}: {
  params: CardRequestParams;
  setParams: (_: CardRequestParams) => void;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  const { isMobile } = useWindowWidth();

  const addressSchema = Yup.object().shape({
    address1: Yup.string().required('Address line 1 is required'),
    address2: Yup.string().nullable(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('Zip is required'),
  });

  const handleContinue = (values: AddressType) => {
    setParams({ ...params, shipping_address: values });
    onNext();
  };

  return (
    <>
      <ModalHeader title="Shipping Address" onClose={closeModal} />
      <Formik
        initialValues={params.shipping_address || {}}
        validationSchema={addressSchema}
        onSubmit={handleContinue}
      >
        {(props: FormikProps<AddressType>) => (
          <>
            <ModalBodyContent overflowVisible={true}>
              <Form onSubmit={props.handleSubmit} className="md:max-w-md">
                <div className="mb-12 py-4">
                  <AddressInput
                    addressValues={props.values}
                    setAddressValues={(addressValues) =>
                      props.setValues(addressValues as AddressType)
                    }
                    label=""
                    errors={props.errors || {}}
                    setErrors={() => {}}
                    isMobile={isMobile}
                  />
                </div>
              </Form>
            </ModalBodyContent>
            <ModalFooter>
              <Button className="flex-1" size="small" color="secondary" onClick={onBack}>
                Back
              </Button>
              <Button className="flex-1" size="small" onClick={() => props.handleSubmit()}>
                Continue
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  );
};
