import useAuth from '@app/hooks/useAuth';
import useChannelPartner from '@app/hooks/useChannelPartner';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ReactElement, useState } from 'react';

const TosReacceptanceModal = ({
  open,
  onClose,
  onAccept,
}: {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { supportPhoneNumber, supportEmailAddress } = useChannelPartner();
  const { user } = useAuth();

  const isAtobUser = user?.email?.includes('@atob.');

  const acceptTosAndClose = async () => {
    setLoading(true);
    try {
      await axios.post('/byoc/reaccept_tos');
      setLoading(false);
      onAccept();
      onClose();
    } catch (e: unknown) {
      if (guardAxiosError(e) && e.response?.status === 404) {
        addToast('Only the Account Owner can accept the terms.', { appearance: 'error' });
      } else {
        Sentry.captureException(e);
        addToast('Something went wrong, please try again later.', { appearance: 'error' });
      }
      setLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <ModalHeader title="Terms of Service Update" onClose={isAtobUser ? onClose : undefined} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mb-4 text-sm font-normal">
          Please review and accept our updated terms of service to ensure no disruption in using
          your cards. By clicking Accept, you agree to the following documents:
        </div>
        <div className="mx-8 text-sm">
          <ul className="list-disc space-y-1">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/ssa#services-terms"
              >
                Stripe Issuing Accountholder Terms
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/celtic-charge-card"
              >
                Issuing Bank Accountholder Terms: Charge Card
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/issuing-offer-document-fees"
              >
                Commercial financing disclosure, California cardholder only, fee charged
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://stripe.com/legal/connect-account"
              >
                Stripe Connected Account Agreement
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-600"
                href="https://staic-files-hosting.s3.us-west-1.amazonaws.com/AtoB+Platform+Agreement+Latest.pdf"
              >
                AtoB Platform Agreement
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4 text-sm font-normal">
          Please reach out to support at {supportPhoneNumber} or {supportEmailAddress} if you have
          any questions.
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton
          loading={loading}
          autoFocus
          fullWidth
          color="primary"
          onClick={acceptTosAndClose}
        >
          <span>Accept</span>
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default TosReacceptanceModal;
