import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useProduct from '@app/hooks/useProduct';
import Modal, {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export default function WelcomeToAtoBPopup({
  onDismiss,
}: {
  onDismiss: () => void;
}): ReactElement | null {
  const [isOpen, setIsOpen] = useState(true);
  const { partnerName } = useChannelPartner();
  const isPrepaid = useProduct('prepaid');

  const isUF = useIsUberfreight(true);

  return (
    <Modal open={isOpen} maxWidth="xs">
      <ModalHeader
        title={`Welcome to ${isUF ? 'your ' : ''}${partnerName}${isUF ? ' Card' : ''}`}
        headerImage={<img className="w-full max-w-lg" src="/images/welcome_to_atob_header.png" />}
        showHeaderImageWithoutPadding
      />
      <ModalBodyContent>
        Start saving hundreds of dollars{isPrepaid ? ' and build your credit' : ''} simply by paying
        with your {partnerName} card!
      </ModalBodyContent>
      <ModalFooter>
        <Button
          className="flex-1"
          size="medium"
          onClick={() => {
            onDismiss();
            setIsOpen(false);
          }}
        >
          Next
        </Button>
      </ModalFooter>
    </Modal>
  );
}
