import { Treasury } from '@app/@types/customer.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useLocalStorageValues from '@app/hooks/useLocalStorageValues';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getStripeLink } from '../../pages/Wallet/Onboarding';
import { PREPAID_OPT_IN_CONFIRMED } from '../Prepaid/use-prepaid-details';

export default function PrepaidOptInBanner({
  treasury,
}: {
  treasury: Treasury | null;
}): ReactElement | null {
  const navigate = useNavigate();
  const [PREPAID_MARKETING_PAGE_ENABLED] = useFeatureFlags('prepaid_marketing_page');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hideDismissableBanners } = useThemeConfiguration();

  if (hideDismissableBanners) {
    return null;
  }

  const toggle = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    if (PREPAID_MARKETING_PAGE_ENABLED) {
      navigate('/unlimited');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div>
        <div
          className={classNames(
            'bg-ds-cool-gray-900',
            'relative flex w-full items-center justify-between overflow-hidden bg-no-repeat md:h-[86px]',
          )}
        >
          <div className="gap-x-2 px-[32px] py-[22px] text-base text-gray-100 md:w-full">
            <span className="mr-2">
              <b className="font-bold">Introducing AtoB Unlimited:</b> A pay-as-you-go plan with
              deeper fuel discounts, fraud security, and non-fuel spend.
            </span>
            <button className="inline-block font-semibold underline" onClick={handleClick}>
              Learn more
            </button>
          </div>
        </div>
      </div>
      <OptInModal treasury={treasury} isOpen={isModalOpen} toggle={toggle} />
    </>
  );
}

const OptInModal = ({
  treasury,
  isOpen,
  toggle,
}: {
  treasury: Treasury | null;
  isOpen: boolean;
  toggle: () => void;
}) => {
  const [, setLocalStorageValues] = useLocalStorageValues();
  const [loadingStripeEnrollment, setLoadingStripeEnrollment] = useState(false);

  const onRequestPrepaid = async () => {
    setLocalStorageValues({ [PREPAID_OPT_IN_CONFIRMED]: true });
    if (treasury?.status === 'enrolled') {
      window.location.href = '/';
      return;
    }
    setLoadingStripeEnrollment(true);
    await axios.post('/treasury/onboarding/enroll');
    const stripeLink = await getStripeLink();
    setLoadingStripeEnrollment(false);
    window.location.href = stripeLink;
  };

  const ModalMessage = () => (
    <>
      <p className="flex flex-col items-start text-base leading-6">
        <div className="flex flex-col items-center">
          <p>
            AtoB Unlimited is a pay-as-you-go plan that builds business credit, has no spend
            category restrictions, and comes with 3 months of free premium features.
          </p>
          <p className="text-atob-green mt-4 text-base font-bold">
            <Link to="/unlimited">Learn more about Unlimited.</Link>
          </p>
        </div>
      </p>
    </>
  );

  return (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="" onClose={toggle} />
      <ModalBodyContent>
        <div className="flex flex-col items-center">
          <FontAwesomeIcon
            icon={faCreditCardFront}
            className="fa-light bg-green8 text-atob-green mr-1 rounded-full p-5"
            size="2x"
          />
          <div className="m-2" />
          <h2 className="max-w-[440px] text-center text-2xl font-bold">
            Switch to AtoB Unlimited today!
          </h2>
          <div className="m-2" />
          <div className="flex max-w-[400px] flex-col gap-2 text-center font-normal text-gray-900">
            <ModalMessage />
          </div>

          <div className="mb-8 mt-8 flex w-full justify-center">
            <div className="flex w-full flex-col items-center justify-around md:flex-row">
              <div className="w-[220px]">
                <LoadingButton loading={loadingStripeEnrollment} onClick={onRequestPrepaid}>
                  <span>Switch to AtoB Unlimited</span>
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="my-4 w-full text-center text-sm italic text-gray-500">
          Have questions? Reach out to us at{' '}
          <a className="underline" href="mailto:support@atob.com">
            support@atob.com
          </a>{' '}
          to discuss.
        </div>
      </ModalFooter>
    </Modal>
  );
};
