import onCopy from '@app/utils/on-copy';
import { ReactElement, useState } from 'react';
import WalletDetail from './WalletDetail';

const AccountInfo = ({
  routingNumber,
  accountNumber,
}: {
  routingNumber: string | null;
  accountNumber: string | null;
}): ReactElement => {
  const [showRoutingNumber, setShowRoutingNumber] = useState(false);
  const [showAccountNumber, setShowAccountNumber] = useState(false);

  const [copyRoutingNumber, setCopyRoutingNumber] = useState(false);
  const [copyAccountNumber, setCopyAccountNumber] = useState(false);

  const onCopyNumber = (number: string, setCopyNumber: (copied: boolean) => void) =>
    onCopy(number, () => {
      setCopyNumber(true);
      setTimeout(() => {
        setCopyNumber(false);
      }, 5000);
    });

  return (
    <div className="flex w-full flex-col">
      <div className="flex h-3/4 w-full flex-col justify-evenly md:pl-2 md:pr-2">
        <WalletDetail
          title="Routing"
          detailNumber={routingNumber}
          showNumber={showRoutingNumber}
          setShowNumber={setShowRoutingNumber}
          copyNumber={copyRoutingNumber}
          setCopyNumber={setCopyRoutingNumber}
          onCopyNumber={onCopyNumber}
        />
        <WalletDetail
          title="Account"
          detailNumber={accountNumber}
          showNumber={showAccountNumber}
          setShowNumber={setShowAccountNumber}
          copyNumber={copyAccountNumber}
          setCopyNumber={setCopyAccountNumber}
          onCopyNumber={onCopyNumber}
        />
      </div>
    </div>
  );
};

export default AccountInfo;
