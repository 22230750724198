import { getCurrencyFromCents } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { TransferSummary } from '../TransferSummary';
import {
  computeTotalFeeAmount,
  estimatedReceptionTimeMapping,
  FeeConfiguration,
  feeString,
  NormalizedDestination,
} from '../utils';

export const SuccessModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee: FeeConfiguration;
  description: string;
}) => {
  const destinationType = destination.type === 'debit_card' ? 'Card' : 'Account';
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = getCurrencyFromCents(
    computeTotalFeeAmount({ transferAmountCents, feeData: fee }),
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <h3>Transfer initiated</h3>
        <p className="text-secondary text-sm font-normal">
          Your funds should arrive {estimatedReceptionTimeMapping[fee.type]}.
        </p>
      </DialogTitle>
      <DialogContent>
        <div className="mt-2 flex flex-col gap-6">
          <TransferSummary
            recipientName={destination.recipientName}
            destinationType={destinationType}
            lastFour={destination.lastFour}
            feeAmount={feeAmount}
            feeString={feeString(fee)}
            description={description}
            amount={transferAmountCents}
          />
          <div className="flex gap-3">
            <Button color="primary" fullWidth onClick={onClose}>
              Done
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
