import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button, TextField } from '@mui/material';
import { ReactElement } from 'react';
import { CardOrderConstraints, CardRequestParams } from './CardOrderModal';

export const CardOrderQuantity = ({
  params,
  setParams,
  orderConstraints,
  onNext,
  onBack,
  closeModal,
}: {
  params: CardRequestParams;
  setParams: (_: CardRequestParams) => void;
  orderConstraints: CardOrderConstraints;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  let orderLimit =
    params.card_type === 'physical'
      ? orderConstraints.max_cards_allowed_physical
      : orderConstraints.max_cards_allowed_virtual;

  orderLimit = orderLimit === null ? 50 : orderLimit;

  const checkQuantity = (quantity: number | null) => {
    if (!quantity) return 'Required';
    if (orderLimit <= 0)
      return "You can't order more cards at this time. Please contact AtoB support.";
    if (quantity > orderLimit)
      return (
        "You can't order more than " +
        orderLimit +
        ' cards. To place a larger order, please contact AtoB support.'
      );
    return undefined;
  };

  return (
    <>
      <ModalHeader title="Card Quantity" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mb-8 mt-8">
          <TextField
            fullWidth
            id="card-quantity"
            size="small"
            label="Quantity (Max 50)"
            placeholder="How many do you need?"
            helperText={checkQuantity(params.number_of_cards)}
            value={params.number_of_cards > 0 ? params.number_of_cards : undefined}
            onChange={(e) => setParams({ ...params, number_of_cards: Number(e.target.value) })}
            error={!!checkQuantity(params.number_of_cards)}
            disabled={orderLimit <= 0}
          />
        </div>
        {!orderConstraints.virtual_cards_allowed && !orderConstraints.fee_waived && (
          <div>
            <p className="text-sm">Card Fees: $5 per card</p>
          </div>
        )}
        <div className="mb-40" />
      </ModalBodyContent>
      <ModalFooter>
        {orderConstraints.virtual_cards_allowed && (
          <Button className="flex-1" size="small" color="secondary" onClick={onBack}>
            Back
          </Button>
        )}
        <Button
          className="flex-1"
          size="small"
          onClick={onNext}
          disabled={checkQuantity(params.number_of_cards) !== undefined}
        >
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};
