import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import logger from '@app/utils/datadog-logger';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { PropsWithChildren, ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

export type NewFeatureVariant =
  | 'new_product'
  | 'new_feature'
  | 'new_discount'
  | 'offer'
  | 'limited_time_offer'
  | 'no_title';

export const VARIANT_LABEL: Record<NewFeatureVariant, string> = {
  new_feature: 'New Feature',
  new_product: 'New Product',
  new_discount: 'New Discount',
  offer: 'Offer',
  limited_time_offer: 'Limited time offer',
  no_title: '',
};

export interface NewFeatureBannerProps {
  bannerKey: string;
  variant: NewFeatureVariant;
  onClick: () => void;
  actionText: string;
  icon?: IconProp;
  iconImg?: React.ReactNode;
  nonDismissable?: boolean;
}

export const NewFeatureBanner = ({
  bannerKey,
  onClick,
  actionText,
  variant = 'new_product',
  children,
  icon,
  iconImg,
  nonDismissable = false,
}: PropsWithChildren<NewFeatureBannerProps>): ReactElement | null => {
  const { dismissed, loading, setDismissed } = useEventDismissed(bannerKey);
  const { hideDismissableBanners } = useThemeConfiguration();
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '50%',
    onChange: (inView) => {
      if (inView) {
        logger.info('banner-shown', { key: bannerKey });
      }
    },
  });

  if (loading || dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div
      className="bg-blue3 bg-new-product-bg-mobile xl:bg-new-product-bg-desktop relative w-full rounded-md bg-[length:auto_100%] bg-right bg-no-repeat px-4 py-4 text-sm font-normal"
      ref={ref}
    >
      <div className="flex flex-row justify-start xl:items-center">
        <div className="mr-4 flex w-11 shrink-0 justify-center align-middle">
          {iconImg ? (
            <div className="h-11 w-11">{iconImg}</div>
          ) : icon ? (
            <FontAwesomeIcon icon={icon} className="my-auto h-6 w-6 text-white" />
          ) : (
            <div className="flex h-6 rounded border border-white px-2 py-1 text-[10px] uppercase leading-[15px] text-white">
              New
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between gap-1 text-white xl:w-full xl:flex-row">
          <div className="flex flex-col justify-center">
            {variant !== 'no_title' && (
              <div className="text-[10px] uppercase tracking-[.8px] text-gray-300">
                {VARIANT_LABEL[variant]}
              </div>
            )}
            <div className="text-left">{children}</div>
          </div>

          <div className="mt-2 flex flex-row items-center gap-4 xl:mt-0">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                logger.info('banner-cta-clicked', { key: bannerKey });
                onClick();
              }}
              className="h-10 min-w-max bg-white bg-opacity-90 hover:bg-white hover:bg-opacity-90"
            >
              <span className="text-black0">{actionText}</span>
            </Button>

            {!nonDismissable && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  logger.info('banner-dismissed', { key: bannerKey });
                  setDismissed();
                }}
                className="mr-2 bg-opacity-90 text-white decoration-white hover:bg-opacity-90 hover:text-white"
              >
                Dismiss
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
